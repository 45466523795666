/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';
import { OrganizationType } from 'app/globals-classes/OrganizationType';
import { GlobalsService } from 'app/globals-services/globals.service';
import { MtaSuppliersService } from 'app/shared/services/mta-suppliers.service';


export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example',
    },
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example',
    },
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example',
    },
    {
        id: 'Purchase Orders',
        title: 'Purchase Orders',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/purchase-order',
    },
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example',
    },
];










// -------------------------------------------------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------------------------------------------------












// BHive Menus
export const supplierNavigation: FuseNavigationItem[] = [

    {
        id: 'DASHBOARD',
        title: 'Dashboard',
        type: 'group',
        icon: 'heroicons_outline:home',
        link: '/dashboard',
        children: [
            {
                id: 'MY_DASHBOARD',
                title: 'My Dashboard',
                type: 'basic',
                icon: 'heroicons_outline:home',
                link: '/dashboard',
            },
            {
                id: 'NOTIFICATION_BOX.OTHER',
                title: 'Inbox',
                type: 'basic',
                icon: 'heroicons_outline:mail',
                link: '/notification-inbox/mailbox',
                badge: {
                    title: undefined,
                    classes: ''
                }
            }
        ]
    },

    {
        id: 'DATA_INPUT',
        title: 'Data Input',
        type: 'group',
        icon: 'heroicons_outline:shopping-bag',
        children: [

            {
                id: 'MY_INVENTORIES',
                title: 'My Inventories',
                type: 'collapsable',
                icon: 'heroicons_outline:document-search',
                hidden: function () {
                    return !(GlobalsService.subscriptionTypeAdmin === 'Full Bhive')
                },
                children: [
                    {
                        id: 'CREATE_NEW_INVENTORY',
                        title: 'Create New Inventory',
                        type: 'basic',
                        icon: 'mat_outline:add',
                        link: '/create-new-inventory',


                    },
                    {
                        id: 'OVERVIEW',
                        title: 'Overview',
                        type: 'basic',
                        icon: 'mat_outline:store',
                        link: '/inventories'
                    },
                    // {
                    //     id: 'MONITORING',
                    //     title: 'Monitoring',
                    //     type: 'basic',
                    //     icon: 'mat_outline:store',
                    //     link: '/new-supplier-view/list',
                    //     hidden: () => {
                    //         if ( GlobalsService.iswalmartSupplier)  {
                    //             return false;
                    //         } else {
                    //             return true;
                    //         }
                    //     }
                    // },

                    {
                        id: 'CHEMICAL_PUCHASE_ORDER',
                        title: 'Chemical Purchase Order',
                        type: 'basic',
                        icon: 'heroicons_outline:shopping-bag',
                        link: '/purchase-order',
                    }

                ]
            },
            {
                id: 'MY_CHEMSTORE',
                title: 'My ChemStore',
                type: 'basic',
                icon: 'heroicons_outline:document-search',
                link: '/supplier-login/my-chemstore',
                hidden: function () {
                    return !(GlobalsService.subscriptionTypeAdmin === 'Full Bhive')
                }
            },
            // {
            //     id: 'MY_CHEMSTORE',
            //     title: 'My ChemStore',
            //     type: 'basic',
            //     icon: 'heroicons_outline:document-search',
            //     link: '/my-chemstore/list',
            //     hidden: function () {
            //         return !(GlobalsService.subscriptionTypeAdmin === 'Full Bhive')
            //     }
            // },

            {
                id: 'MY_FACILITY',
                title: 'My Facilities',
                type: 'collapsable',
                icon: 'heroicons_outline:shopping-bag',

                children: [
                    {
                        id: 'DOCS_CERTIFICATES',
                        title: 'Documents/Certificates',
                        type: 'basic',
                        icon: 'mat_outline:summarize',
                        link: '/environmental-data/documentsCertificates',
                    },
                    //  {
                    //     id: 'PRODUCTION',
                    //     title: 'Production',
                    //     type: 'basic',
                    //     icon: 'mat_outline:stacked_bar_chart',
                    //     link: '/environmental-data/production-data'
                    // }, 
                    {
                        id: 'WATER',
                        title: 'Water',
                        type: 'basic',
                        icon: 'mat_outline:water',
                        link: '/environmental-data/water',
                    }, {
                        id: 'ENERGY',
                        title: 'Energy',
                        type: 'basic',
                        icon: 'mat_outline:local_fire_department',
                        link: '/environmental-data/energy'
                    },
                    {
                        id: 'CHEMICAL_DATA',
                        title: 'Chemical Data',
                        type: 'basic',
                        icon: 'mat_outline:description',
                        link: '/environmental-data/chemical-data',
                    }
                    , {
                        id: 'HEALTH_AND_SAFTY',
                        title: 'Health & Safety',
                        type: 'basic',
                        icon: 'mat_outline:health_and_safety',
                        link: '/environmental-data/health'
                    }
                ]
            }
        ]
    },
    {
        id: 'PRODUCT_ORDER',
        title: 'Product Order',
        type: 'basic',
        icon: 'heroicons_outline:template',
        link: '/supplier-login/product-module',

        hidden: function () {

            return GlobalsService.isDPPBrandSupplier || GlobalsService.isDPPBrandSupplierChina ? false : true


        },
    },
    {
        id: 'ANALYTICS_AND_REPORT',
        title: 'Analytics & Report',
        type: 'group',
        icon: 'heroicons_outline:shopping-bag',

        children: [
            {
                id: 'MY_ANALYTICS',
                title: 'My Analytics',
                type: 'basic',
                icon: 'heroicons_outline:chart-pie',
                link: '/factory-analytics',
                hidden: function () {
                    return !(GlobalsService.subscriptionTypeAdmin === 'Full Bhive')
                },
            },
            {
                id: 'INCHECK',
                title: 'InCheck',
                type: 'basic',
                icon: 'mat_outline:checklist',
                link: '/inventory/incheck',
                hidden: function () {
                    return !(GlobalsService.subscriptionTypeAdmin === 'Full Bhive')
                },
            },

            {
                id: 'EXTERNAL_REPORTS',
                title: 'External Reports',
                type: 'collapsable',
                icon: 'heroicons_outline:bookmark-alt',
                hidden: function () {

                    if (GlobalsService.isKontoorSupplier ||
                        GlobalsService.iswalmartSupplier ||
                        GlobalsService.isTheChildrenPlaceSupplier ||
                        GlobalsService.isCarterSupplier ||
                        GlobalsService.isKomarBrandSupplier ||
                        GlobalsService.isOneJeanswearGroupSupplier
                    ) {

                        return false;

                    } else {

                        return true;

                    }

                },
                children: [
                    {
                        id: 'HIGG_FEM_3_0_DATA_BUTTON',
                        title: 'Higg FEM Data',
                        type: 'basic',
                        icon: 'heroicons_outline:document-report',
                        link: '/higg-certificate'
                    },
                    {
                        id: 'EXTERNAL_INCHECK',
                        title: 'External InCheck Reports',
                        type: 'basic',
                        icon: 'heroicons_outline:academic-cap',
                        link: '/incheck-certificate',
                        hidden: function () {

                            if (GlobalsService.iswalmartSupplier) {
                                return false;
                            } else {
                                return true;
                            }

                        }
                    },
                    // {
                    //     id: 'Others Reports',
                    //     title: 'Others',
                    //     type: 'basic',
                    //     icon: 'archive',
                    //     link: '/other-certificate',
                    // },
                ],
            },
        ]
    },

    {
        id: 'PERFORMANCE_MGT',
        title: 'Performance Management',
        type: 'group',
        icon: 'heroicons_outline:clipboard-list',
        hidden: function () {
            return !(GlobalsService.subscriptionTypeAdmin === 'Full Bhive')
        },
        children: [

            {
                id: 'CAS_SCREENER',
                title: 'CAS Screener',
                type: 'basic',
                icon: 'heroicons_outline:search',
                link: '/cas-screener/factory',
            },
            {
                id: 'CAP',
                title: 'CAP',
                type: 'basic',
                icon: 'mat_outline:pending_actions',
                link: '/my-chemstore/cap',
            },
            {
                id: 'SUBSTITUTION',
                title: 'Substitution',
                type: 'collapsable',
                icon: 'mat_outline:loop',
                children: [
                    {
                        id: 'OVERVIEW',
                        title: 'OEKO-TEX® Resources',
                        type: 'collapsable',
                        icon: 'mat_outline:loop',
                        children: [
                            {
                                id: 'OVERVIEW',
                                title: 'OEKO-TEX® Buying Guide',
                                type: 'basic',
                                icon: 'mat_outline:loop',
                                link: 'https://www.oeko-tex.com/en/buying-guide',
                                externalLink: true,
                                target: '_blank',
                            },
                            {
                                id: 'OVERVIEW',
                                title: 'Accepted ACPs',
                                type: 'basic',
                                icon: 'mat_outline:loop',
                                link: 'https://www.oeko-tex.com/en/apply-here/active-chemical-products/accepted-acps',
                                externalLink: true,
                                target: '_blank',
                            },
                        ]
                    },
                    {
                        id: 'OVERVIEW',
                        title: 'GOTS Resources',
                        type: 'basic',
                        icon: 'mat_outline:loop',
                        link: 'https://global-standard.org/find-suppliers-shops-and-inputs/approved-chemical-inputs',
                        externalLink: true,
                        target: '_blank',
                    },
                    {
                        id: 'OVERVIEW',
                        title: 'ChemSec Marketplace',
                        type: 'basic',
                        icon: 'mat_outline:loop',
                        link: 'https://marketplace.chemsec.org/alternatives?groups=5',
                        externalLink: true,
                        target: '_blank',
                    },
                    {
                        id: 'OVERVIEW',
                        title: 'Scivera Screened Chemistry',
                        type: 'basic',
                        icon: 'mat_outline:loop',
                        link: 'https://www.enhesa.com/sustainable-chemistry/screened-chemistry-certification/',
                        externalLink: true,
                        target: '_blank'
                        //link: '/app-scrivera'
                    },
                    {
                        id: 'OVERVIEW',
                        title: 'ToxServices Resources',
                        type: 'basic',
                        icon: 'mat_outline:loop',
                        link: '/app-tox-services-resources',
                    }
                ]
            },
        ]
    },

    {
        id: 'CUSTOMER_SPECIFIC',
        title: 'Customer Specific',
        type: 'group',
        hidden: function () {
            return !(GlobalsService.subscriptionTypeAdmin === 'Full Bhive')
        },
        children: [

            {
                id: 'CHEMIQ',
                title: 'CHEMIQ',
                type: 'basic',
                icon: 'heroicons_outline:book-open',
                link: '/chemIq/supplier-chemIq',
                hidden: function () {
                    if (
                        GlobalsService.userRole.organizationType ==
                        OrganizationType.SUPPLIER &&
                        GlobalsService.userRole.chemIq != '' &&
                        GlobalsService.userRole.chemIq === 'vfSupplier'
                    ) {
                        //  console.log(GlobalsService.userRole.organizationType == OrganizationType.SUPPLIER && GlobalsService.userRole.chemIq === "vfSupplier")
                        return false;
                    } else {
                        return true;
                    }
                },
            },
            {
                id: 'CHEMIQ',
                title: 'ChemIQ',
                type: 'basic',
                icon: 'heroicons_outline:book-open',
                link: '/chemIq',
                hidden: function () {
                    console.info(GlobalsService.userRole.chemIq);
                    if (
                        GlobalsService.userRole.organizationType ==
                        OrganizationType.BRAND &&
                        GlobalsService.userRole.chemIq != '' &&
                        GlobalsService.userRole.chemIq === 'brand'
                    ) {
                        return false;
                    } else {
                        return true;
                    }
                },
            },

            {
                id: 'SUPPLIER_CONNECTION',
                title: 'Supplier Connections',
                type: 'basic',
                icon: 'heroicons_outline:user-group',
                link: '/connectivity/invitation-list',
            }

        ]
    },

    {
        id: 'PROFILE',
        title: 'Profile',
        type: 'group',

        children: [
            // {
            //     id: 'MY_PROFILE_BUTTON',
            //     title: 'My Profile',
            //     type: 'basic',
            //     icon: 'heroicons_outline:user-circle',
            //     link: '/wet-processing-units',
            // },
            {
                id: 'MY_PROFILE_BUTTON',
                title: 'My Profile',
                type: 'basic',
                icon: 'heroicons_outline:user-circle',
                link: '/supplier-login/profile'
            },

            {
                id: 'KONTOOR_USER_GUIDE',
                title: 'Kontoor The BHive Campus',
                type: 'basic',
                icon: 'heroicons_outline:information-circle',
                link: 'https://www.thebhive.net/user-guide-cmp-en-1',
                externalLink: true,
                target: '_blank',
                hidden: function () {
                    return GlobalsService.isKontoorSupplier  && GlobalsService.serverEnvironment === 'Europe' ? false : true;
                },
            },

            {
                id: 'KONTOOR_USER_GUIDE',
                title: 'Kontoor The BHive Campus',
                type: 'basic',
                icon: 'heroicons_outline:information-circle',
                link: 'https://thebhive.net/user-guide-cmp-cn-1',
                externalLink: true,
                target: '_blank',
                hidden: function () {
                    return GlobalsService.isKontoorSupplier  && GlobalsService.serverEnvironment === 'China' ? false : true;
                },
            },

            {
                id: 'WALMART_USER_GUIDE',
                title: 'Walmart The BHive Campus',
                type: 'basic',
                icon: 'heroicons_outline:information-circle',
                link: 'https://thebhive.net/user-guide-cmp-en',
                externalLink: true,
                target: '_blank',
                hidden: function () {
                    return GlobalsService.iswalmartSupplier   && GlobalsService.serverEnvironment === 'Europe' ? false : true;
                },
            },

            {
                id: 'WALMART_USER_GUIDE',
                title: 'Walmart The BHive Campus',
                type: 'basic',
                icon: 'heroicons_outline:information-circle',
                link: 'https://thebhive.net/user-guide-cmp-cn',
                externalLink: true,
                target: '_blank',
                hidden: function () {
                    return GlobalsService.iswalmartSupplier && GlobalsService.serverEnvironment === 'China' ? false : true;
                },
            },

            {
                id: 'USER_GUIDE',
                title: 'The BHive Campus',
                type: 'basic',
                icon: 'heroicons_outline:information-circle',
                link: '/user-guide-languages',
            }
        ]
    },


    {
        id: 'SUPPORT_MAIL_TO',
        title: 'For any inquiries please send us an email to support@thebhive.net',
        type: 'basic',
    },
];











// -------------------------------------------------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------------------------------------------------


















export const brandNavigation: FuseNavigationItem[] = [
    {
        id: 'MY_DASHBOARD',
        title: 'Dashboard',
        type: 'basic',
        icon: 'heroicons_outline:home',
        link: '/dashboard',
    },
    // {
    //     id: 'FORMULA_MANAGEMENT',
    //     title: 'Formula Management',
    //     type: 'basic',
    //     icon: 'heroicons_outline:receipt-tax',
    //     link: '/admin/formula-management',
    //     hidden: () => {
    //         if (GlobalsService.isBluwinUser) {
    //             return false;
    //         } else {
    //             return true;
    //         }
    //     }
    // },
    {
        id: 'FORMULA_MANAGEMENT',
        title: 'Formula Management',
        type: 'basic',
        icon: 'heroicons_outline:receipt-tax',
        link: '/new-supplier-view/sdsScreening',
        hidden: () => {
            if (GlobalsService.isBluwinUser) {
                return false;
            } else {
                return true;
            }
        }
    },
    {
        id: 'MONITORING',
        title: 'Monitoring',
        type: 'basic',
        icon: 'mat_outline:store',
        link: '/new-supplier-view/list',
        hidden: () => {
            if (GlobalsService.isBluwinUser ||
                GlobalsService.isTuvUser ||
                GlobalsService.isWalmartUser
                //   || GlobalsService.userRole.organizationType == OrganizationType.IMPORTER

            ) {
                return false;
            } else {
                return true;
            }
        }
    },
    /*
    {
        id: 'NOTIFICATION_BOX.OTHER',
        title: 'Inbox',
        type: 'basic',
        icon: 'heroicons_outline:mail',
        link: '/notification-inbox/mailbox',
        badge: {
            title: undefined,
            classes: ''
        }
    },*/
    {
        id: 'MY_SUPPLIERS',
        title: 'My Suppliers',
        type: 'collapsable',
        icon: 'heroicons_outline:user-group',

        children: [
            {
                id: 'OVERVIEW',
                title: 'Overview',
                type: 'basic',
                icon: 'heroicons_outline:document-search',
                link: '/brand-login/my-supplier',
            },
            // {
            //     id: 'OVERVIEW',
            //     title: 'Overview',
            //     type: 'basic',
            //     icon: 'heroicons_outline:document-search',
            //     link: '/wpu-list',
            // },

            {
                id: 'VENDOR_IMPORTER',
                title: 'Vendors/Brand',
                type: 'basic',
                icon: 'group',
                link: '/supplier-vendors',
                hidden: () => {
                    if (
                        GlobalsService.userRole.uniqueIdentifier === 'WALMART_BHIVE_ID'
                        ||
                        GlobalsService.userRole.uniqueIdentifier === 'INDITEX_BHIVE_ID'
                        ||
                        GlobalsService.userRole.uniqueIdentifier === 'ZALANDO_BHIVE_ID'
                        ||
                        GlobalsService.userRole.uniqueIdentifier === 'OEKO_TEX_BHIVE_ID'
                        ||
                        GlobalsService.userRole.uniqueIdentifier === 'FASHION_BLU_BHIVE_ID'

                    ) {
                        return false;
                    } else {
                        return true;
                    }
                }

            },
            {
                id: 'SUPPLIER_INVENTORIES',
                title: 'Supplier Inventories',
                type: 'basic',
                icon: 'heroicons_outline:shopping-bag',
                link: '/supplier-inventories',
            },
            {
                id: 'PARTNER_CONNECTIONS',
                title: 'Partner Connections',
                type: 'basic',
                icon: 'heroicons_outline:user-add',
                link: '/connection-factory',
            },
            {
                id: 'REPORTS',
                title: 'Reports',
                type: 'basic',
                icon: 'heroicons_outline:chart-pie',
                link: '/inventory/reports',
            }
        ]
    },
    {
        id: 'PRODUCT MODULE',
        title: 'PRODUCT MODULE',
        type: 'collapsable',
        icon: 'heroicons_outline:user-group',

        hidden: function () {

            return GlobalsService.isTakkoBrand || GlobalsService.isDPPBrand || GlobalsService.isLindexBrand || GlobalsService.isDPPBrandChina || GlobalsService.isDummyBestsellerBrand ? false : true


        },
        children: [
            {
                id: 'PRODUCT_ORDER',
                title: 'Product Order',
                type: 'basic',
                icon: 'heroicons_outline:template',
                link: '/brand-login/product-module',
            },
            {
                id: 'MTA_SUPPLIER_CHAIN',
                title: 'Extended Supply Chain',
                type: 'basic',
                icon: 'heroicons_outline:user-group',
                link: '/brand-login/my-suppliers',
                function: () => {
                    MtaSuppliersService.isRoutingFromMenu = true;
                }
            }
        ]
    },
    {
        id: 'STEP_DASHBOARD',
        title: 'STeP Dashboard',
        type: 'basic',
        icon: 'mat_outline:analytics',
        link: '/step-dashboard',
        hidden: function () {

            return GlobalsService.isOekotex ? false : true


        },
    },
    {
        id: 'CAS_SCREENER',
        title: 'CAS Screener',
        type: 'basic',
        icon: 'heroicons_outline:search',
        link: '/cas-screener/brand',
    },
    {
        id: 'ANALYTICS',
        title: 'Analytics',
        type: 'collapsable',
        icon: 'heroicons_outline:chart-bar',
        children: [
            {
                id: 'ANALYTICS',
                title: 'Analytics',
                type: 'basic',
                icon: 'heroicons_outline:chart-bar',
                link: '/supplier-reports',
            },
            {
                id: 'MONTHLY_REPORT',
                title: 'Monthly Report',
                type: 'basic',
                icon: 'mat_outline:download',
                link: '/monthly-report',
                hidden: function () {
                    return GlobalsService.isKontoor || GlobalsService.isAldi || GlobalsService.isHofer || GlobalsService.isAldiImporterBrand || GlobalsService.isAldiSourcing || GlobalsService.isInditex || GlobalsService.isMiles || GlobalsService.isHm ? false : true;
                },
            },
            {
                id: 'MONTHLY_REPORT',
                title: 'Monthly Report',
                type: 'basic',
                icon: 'mat_outline:download',
                link: '/default-monthly-report',
                hidden: function () {
                    return !(GlobalsService.isKontoor || GlobalsService.isAldi || GlobalsService.isHofer || GlobalsService.isAldiImporterBrand || GlobalsService.isAldiSourcing || GlobalsService.isInditex || GlobalsService.isMiles || GlobalsService.isHm || GlobalsService.isCandA || GlobalsService.isNike ) ? false : true;
                },
            },
            {
                id: 'SOLVENT_REPORT',
                title: 'Solvent Report',
                type: 'basic',
                icon: 'mat_outline:download',
                link: '/monthly-report',
                hidden: function () {
                    return GlobalsService.isNike ? false : true;
                },
            },
            {
                id: 'MONTHLY_REPORT',
                title: 'Monthly Report',
                type: 'basic',
                icon: 'mat_outline:download',
                link: '/c-a-monthly-report',
                hidden: function () {
                    return GlobalsService.isCandA ? false : true;
                },
            },
            {
                id: 'CUSTOM_ANALYTICS',
                title: 'Custom dashboards',
                type: 'basic',
                icon: 'heroicons_outline:chart-pie',
                link: '/custom-dashboard',
            }

        ],

    },
    // {
    //     id: 'ANALYTICS',
    //     title: 'Analytics',
    //     type: 'basic',
    //     icon: 'heroicons_outline:chart-bar',
    //     link: '/supplier-reports',
    //     hidden: function () {
    //         return ( GlobalsService.isNike) ? false : true;
    //     },
    // },
    {
        id: 'SUBSTITUTION',
        title: 'Substitution',
        type: 'collapsable',
        icon: 'mat_outline:loop',
        children: [
            {
                id: 'OVERVIEW',
                title: 'OEKO-TEX® Resources',
                type: 'collapsable',
                icon: 'mat_outline:loop',
                children: [
                    {
                        id: 'OVERVIEW',
                        title: 'OEKO-TEX® Buying Guide',
                        type: 'basic',
                        icon: 'mat_outline:loop',
                        link: 'https://www.oeko-tex.com/en/buying-guide',
                        externalLink: true,
                        target: '_blank',
                    },
                    {
                        id: 'OVERVIEW',
                        title: 'Accepted ACPs',
                        type: 'basic',
                        icon: 'mat_outline:loop',
                        link: 'https://www.oeko-tex.com/en/apply-here/active-chemical-products/accepted-acps',
                        externalLink: true,
                        target: '_blank',
                    },
                ]
            },
            {
                id: 'OVERVIEW',
                title: 'GOTS Resources',
                type: 'basic',
                icon: 'mat_outline:loop',
                link: 'https://global-standard.org/find-suppliers-shops-and-inputs/approved-chemical-inputs',
                externalLink: true,
                target: '_blank',
            },
            {
                id: 'OVERVIEW',
                title: 'ChemSec Marketplace',
                type: 'basic',
                icon: 'mat_outline:loop',
                link: 'https://marketplace.chemsec.org/alternatives?groups=5',
                externalLink: true,
                target: '_blank',
            },
            {
                id: 'OVERVIEW',
                title: 'Scivera Resources',
                type: 'basic',
                icon: 'mat_outline:loop',
                link: '/app-scrivera'
            },
            {
                id: 'OVERVIEW',
                title: 'ToxServices Resources',
                type: 'basic',
                icon: 'mat_outline:loop',
                link: '/app-tox-services-resources',
            }
        ]
    },




    {
        id: 'SHARED_CONNECTION',
        title: 'Shared Connections',
        type: 'basic',
        icon: 'heroicons_outline:user-group',
        link: '/connectivity',
        hidden: function () {
            //  if (GlobalsService.userRole.organizationType == OrganizationType.SUPPLIER && (GlobalsService.userRole.uniqueIdentifier != "" && GlobalsService.userRole.uniqueIdentifier === "KONTOOR_BHIVE_ID")) {
            // console.info(
            //     'uniqueIdentifier :' + GlobalsService.userRole.uniqueIdentifier
            // );
            if (
                GlobalsService.userRole.uniqueIdentifier === 'KONTOOR_BHIVE_ID'
            ) {
                return false;
            } else {
                return true;
            }
        },
    },
    {
        id: 'CHEMIQ',
        title: 'ChemIQ',
        type: 'basic',
        icon: 'heroicons_outline:book-open',
        link: '/chemIq',
        hidden: function () {
            if (
                GlobalsService.userRole.organizationType ==
                OrganizationType.BRAND &&
                GlobalsService.userRole.chemIq != '' &&
                GlobalsService.userRole.chemIq === 'brand'
            ) {
                return false;
            } else {
                return true;
            }
        },
    },
    {
        id: 'USER_GUIDE',
        title: 'The BHive Campus',
        type: 'basic',
        icon: 'heroicons_outline:information-circle',
        link: '/user-guide-languages',
    },
    {
        id: 'SUPPORT_MAIL_TO',
        title: 'For any inquiries please send us an email to support@thebhive.net',
        type: 'basic',
    }


];




















// -------------------------------------------------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------------------------------------------------












export const mtsNavigation: FuseNavigationItem[] = [
    // {
    //     id: 'Supplier Inventories',
    //     title: 'Supplier Inventories',
    //     type: 'basic',
    //     icon: 'heroicons_outline:shopping-bag',
    //     link: '/supplier-inventories',
    // },
    {
        id: 'MY_SUPPLIER',
        title: 'My Suppliers',
        type: 'collapsable',
        icon: 'heroicons_outline:user-group',

        children: [
            // {
            //     id: 'OVERVIEW',
            //     title: 'Overview',
            //     type: 'basic',
            //     icon: 'heroicons_outline:document-search',
            //     link: '/wpu-list',
            // },
            {
                id: 'OVERVIEW',
                title: 'Overview',
                type: 'basic',
                icon: 'heroicons_outline:document-search',
                link: '/brand-login/my-supplier',
            },
            {
                id: 'SUPPLIER_INVENTORIES',
                title: 'Supplier Inventories',
                type: 'basic',
                icon: 'heroicons_outline:shopping-bag',
                link: '/supplier-inventories',
            },
            {
                id: 'REPORTS',
                title: 'Reports',
                type: 'basic',
                icon: 'heroicons_outline:chart-pie',
                link: '/inventory/reports',
            }
        ]
    },
    {
        id: 'ANALYTICS',
        title: 'Analytics',
        type: 'basic',
        icon: 'heroicons_outline:chart-bar',
        link: '/supplier-reports',
    },
    /*
    {
        id: 'NOTIFICATION_BOX.OTHER',
        title: 'Inbox',
        type: 'basic',
        icon: 'heroicons_outline:mail',
        link: '/notification-inbox/mailbox',
        badge: {
            title: undefined,
            classes: ''
        }
    },*/

    // {
    //     id: 'Suppliers',
    //     title: 'Suppliers',
    //     type: 'basic',
    //     icon: 'heroicons_outline:user-group',
    //     link: '/wpu-list',
    // },
    {
        id: 'MTS_CHEMIQ',
        title: 'MTS ChemIQ',
        type: 'basic',
        icon: 'heroicons_outline:book-open',
        link: '/chemIq/chemIq-programs',
        hidden: function () {
            if (
                GlobalsService.userRole.organizationType ==
                OrganizationType.BRAND &&
                GlobalsService.userRole.chemIq != '' &&
                GlobalsService.userRole.chemIq === 'mts'
            ) {
                return false;
            } else {
                return true;
            }
        },
    },
    {
        id: 'SUPPORT_MAIL_TO',
        title: 'For any inquiries please send us an email to support@thebhive.net',
        type: 'basic',
    },
];


export const importerNavigation: FuseNavigationItem[] = [
    {
        id: 'MY_DASHBOARD',
        title: 'Dashboard',
        type: 'basic',
        icon: 'heroicons_outline:home',
        link: '/dashboard',
    },
    /*
    {
        id: 'NOTIFICATION_BOX.OTHER',
        title: 'Inbox',
        type: 'basic',
        icon: 'heroicons_outline:mail',
        link: '/notification-inbox/mailbox',
        badge: {
            title: undefined,
            classes: ''
        }
    },
    */
    {
        id: 'MY_SUPPLIER',
        title: 'My Suppliers',
        type: 'collapsable',
        icon: 'heroicons_outline:user-group',

        children: [
            {
                id: 'OVERVIEW',
                title: 'Overview',
                type: 'basic',
                icon: 'heroicons_outline:document-search',
                link: '/brand-login/my-supplier',
            },
            // {
            //     id: 'OVERVIEW',
            //     title: 'Overview',
            //     type: 'basic',
            //     icon: 'heroicons_outline:document-search',
            //     link: '/wpu-list',
            // },
            // {
            //     id: 'MONITORING',
            //     title: 'Monitoring',
            //     type: 'basic',
            //     icon: 'mat_outline:store',
            //     link: '/new-supplier-view/list',
            //     hidden: () => {
            //         if (GlobalsService.isBluwinUser ||
            //             GlobalsService.isTuvUser ||
            //             GlobalsService.isWalmartUser
            //             ||   GlobalsService.userRole.organizationType == OrganizationType.IMPORTER

            //         ) {
            //             return false;
            //         } else {
            //             return true;
            //         }
            //     }
            // },
            {
                id: 'SUPPLIER_INVENTORIES',
                title: 'Supplier Inventories',
                type: 'basic',
                icon: 'heroicons_outline:shopping-bag',
                link: '/supplier-inventories',
            },
            {
                id: 'PARTNER_CONNECTIONS',
                title: 'Partner Connections',
                type: 'basic',
                icon: 'heroicons_outline:user-add',
                link: '/connection-factory',
            },
            {
                id: 'REPORTS',
                title: 'Reports',
                type: 'basic',
                icon: 'heroicons_outline:chart-pie',
                link: '/inventory/reports',
            }
        ]
    },
    {
        id: 'PRODUCT_ORDER',
        title: 'Product Order',
        type: 'basic',
        icon: 'heroicons_outline:template',
        link: '/brand-login/product-module',


        hidden: function () {

            return GlobalsService.isDPPBrandSupplier || GlobalsService.isDPPBrandSupplierChina ? false : true


        },

    },
    {
        id: 'STEP_DASHBOARD',
        title: 'STeP Dashboard',
        type: 'basic',
        icon: 'mat_outline:analytics',
        link: '/step-dashboard',
        hidden: function () {

            return GlobalsService.isOekotex ? false : true

        },
    },
    {
        id: 'ANALYTICS',
        title: 'Analytics',
        type: 'collapsable',
        icon: 'heroicons_outline:chart-bar',
        children: [
            {
                id: 'ANALYTICS',
                title: 'Analytics',
                type: 'basic',
                icon: 'heroicons_outline:chart-bar',
                link: '/supplier-reports',
            },
            {
                id: 'MONTHLY_REPORT',
                title: 'Monthly Report',
                type: 'basic',
                icon: 'mat_outline:download',
                link: '/monthly-report',
                hidden: function () {
                    return GlobalsService.isKontoor || GlobalsService.isAldi || GlobalsService.isHofer || GlobalsService.isAldiImporterBrand || GlobalsService.isAldiSourcing || GlobalsService.isInditex || GlobalsService.isMiles || GlobalsService.isHm ? false : true;
                },
            },
            {
                id: 'MONTHLY_REPORT',
                title: 'Monthly Report',
                type: 'basic',
                icon: 'mat_outline:download',
                link: '/default-monthly-report',
                hidden: function () {
                    return !(GlobalsService.isKontoor || GlobalsService.isAldi || GlobalsService.isHofer || GlobalsService.isAldiImporterBrand || GlobalsService.isAldiSourcing || GlobalsService.isInditex || GlobalsService.isMiles || GlobalsService.isHm || GlobalsService.isCandA || GlobalsService.isNike) ? false : true;
                },
            },
        ],
    },
    {
        id: 'SUBSTITUTION',
        title: 'Substitution',
        type: 'collapsable',
        icon: 'mat_outline:loop',
        children: [
            {
                id: 'OVERVIEW',
                title: 'OEKO-TEX® Resources',
                type: 'collapsable',
                icon: 'mat_outline:loop',
                children: [
                    {
                        id: 'OVERVIEW',
                        title: 'OEKO-TEX® Buying Guide',
                        type: 'basic',
                        icon: 'mat_outline:loop',
                        link: 'https://www.oeko-tex.com/en/buying-guide',
                        externalLink: true,
                        target: '_blank',
                    },
                    {
                        id: 'OVERVIEW',
                        title: 'Accepted ACPs',
                        type: 'basic',
                        icon: 'mat_outline:loop',
                        link: 'https://www.oeko-tex.com/en/apply-here/active-chemical-products/accepted-acps',
                        externalLink: true,
                        target: '_blank',
                    },
                ]
            },
            {
                id: 'OVERVIEW',
                title: 'GOTS Resources',
                type: 'basic',
                icon: 'mat_outline:loop',
                link: 'https://global-standard.org/find-suppliers-shops-and-inputs/approved-chemical-inputs',
                externalLink: true,
                target: '_blank',
            },
            {
                id: 'OVERVIEW',
                title: 'ChemSec Marketplace',
                type: 'basic',
                icon: 'mat_outline:loop',
                link: 'https://marketplace.chemsec.org/alternatives?groups=5',
                externalLink: true,
                target: '_blank',
            },
            {
                id: 'OVERVIEW',
                title: 'Scivera Resources',
                type: 'basic',
                icon: 'mat_outline:loop',
                link: '/app-scrivera'
            },
            {
                id: 'OVERVIEW',
                title: 'ToxServices Resources',
                type: 'basic',
                icon: 'mat_outline:loop',
                link: '/app-tox-services-resources',
            }
        ]
    },

    {
        id: 'SUPPORT_MAIL_TO',
        title: 'For any inquiries please send us an email to support@thebhive.net',
        type: 'basic',
    },
];


export const adminNavigation: FuseNavigationItem[] = [
    {
        id: 'CUSTOM_ANALYTICS',
        title: 'Custom dashboards',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/custom-dashboard',
    },
    {
        id: 'ADMIN_PANEL_BUTTON',
        title: 'Admin Panel',
        type: 'basic',
        icon: 'heroicons_outline:library',
        link: '/admin/admin-panel',
    },
    // {
    //     id: 'NOTIFICATION_BOX.ADMIN',
    //     title: 'Messages',
    //     type: 'basic',
    //     icon: 'heroicons_outline:mail',
    //     link: '/notification-inbox/mailbox',
    //     badge: {
    //         title: undefined,
    //         classes: ''
    //     }
    // },
    {
        id: 'FORMULA_MANAGEMENT',
        title: 'Formula Management',
        type: 'basic',
        icon: 'heroicons_outline:receipt-tax',
        link: '/admin/formula-management',
    },
    {
        id: 'INCHECK_SUBSCRIPTIONS_BUTTON',
        title: 'InCheck Subscriptions',
        type: 'basic',
        icon: 'heroicons_outline:currency-euro',
        link: '/admin/incheck-subscriptions',
    },
    {
        id: 'REFERRAL_LINKS_BUTTON',
        title: 'Referral Links',
        type: 'basic',
        icon: 'heroicons_outline:link',
        link: '/admin/sponsor-link',
    },
    {
        id: 'USER_MANAGEMENT_BUTTON',
        title: 'User Management',
        type: 'basic',
        icon: 'heroicons_outline:users',
        link: '/admin/user-management',
    },
    {
        id: 'USER_ONBOARDING_BUTTON',
        title: 'User Onboarding',
        type: 'basic',
        icon: 'heroicons_outline:user-add',
        link: '/admin/user-onboarding',
    },

    {
        id: 'USER_GUIDE',
        title: 'The BHive Campus',
        type: 'basic',
        icon: 'heroicons_outline:information-circle',
        link: '/user-guide-languages',
    },
    {
        id: 'CHEMIQ_STATUS',
        title: 'ChemIQ Status',
        type: 'basic',
        icon: 'heroicons_outline:book-open',
        link: '/chemIq-status',
    },
    // {
    //     id: 'NOTIFICATION_TEMPLATE_BUTTON',
    //     title: 'Notification Template',
    //     type: 'basic',
    //     icon: 'heroicons_outline:document-text',
    //     link: '/admin-template-management',
    // },
    {
        id: 'NOTIFICATION_TEMPLATE_BUTTON',
        title: 'Notification Template',
        type: 'basic',
        icon: 'heroicons_outline:document-text',
        link: '/admin-template-management',
    },
    {
        id: 'SUPPORT_MAIL_TO',
        title: 'For any inquiries please send us an email to support@thebhive.net',
        type: 'basic',
    },
];





//----------------------------------------------------------------------------------------------------------------------------------------




// {
//     id: 'TRANSFER',
//     title: 'Transfer',
//     type: 'basic',
//     icon: 'mat_outline:transfer_within_a_station',
//     link: 'input-inventory',
// },
