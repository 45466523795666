import { Route } from '@angular/router';
import { InitialDataResolver } from 'app/app.resolvers';
import { LayoutComponent } from 'app/layout/layout.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DocumentcenterComponent } from './documentcenter/documentcenter.component';
import { FactoryAnalyticsListComponent } from './factory-analytics/factory-analytics-list/factory-analytics-list.component';
import { FactoryReportingComponent } from './factory-reporting/factory-reporting.component';
import { SubscriptionFormComponent } from './gateway/subscription-form/subscription-form.component';
import { SupplierInventoriesOverviewComponent } from './inventories-overview/supplier-inventories-overview/supplier-inventories-overview.component';
import { InputInventoryComponent } from './inventory/input-inventory/input-inventory.component';
import { MarketplaceComponent } from './marketplace/marketplace.component';
import { KeycloakGuard } from './modules/auth/login/keycloak.guard';
import { PasswordResetComponent } from './modules/auth/login/password-reset/password-reset.component';
import { StepDashboardComponent } from './step-dashboard/step-dashboard.component';
import { ScriveraComponent } from './substitution/scrivera/scrivera.component';
import { ToxServicesResourcesComponent } from './substitution/tox-services-resources/tox-services-resources.component';
import { SupplierReportingComponent } from './supplier-reporting/supplier-reporting.component';
import { UserGuideComponent } from './user-guide/user-guide/user-guide.component';
import { UserSignupComponent } from './user-signup/user-signup.component';
import { WetProcessingUnitComponent } from './wet-processing-unit/wet-processing-unit.component';
import { WpuListComponent } from './wet-processing-unit/wpu-list.component';
import { MonthlyReportComponent } from './brand-analytics/monthly-report/monthly-report.component';
import { CAMonthlyReportComponent } from './brand-analytics/c-a-monthly-report/c-a-monthly-report.component';

import { CustomDashboardComponent } from './custom-dashboard/custom-dashboard.component';
import { DefaultMonthlyReportComponent } from './brand-analytics/default-monthly-report/default-monthly-report.component';

// @formatter:off
// tslint:disable:max-line-length
export const appRoutes: Route[] = [




  // Redirect signed in user to the '/example'
  //
  // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  { path: '', pathMatch: 'full', redirectTo: 'sign-in' },
  //{path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'example'},

  // Auth routes for guests
  {
    path: '',

    component: LayoutComponent,
    data: {
      layout: 'empty'
    },
    children: [
      { path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule) },

      { path: 'password-reset', canActivate: [KeycloakGuard], component: PasswordResetComponent },
      { path: 'sign-up', component: UserSignupComponent },
    ]
  },

  // Auth routes for authenticated users
  {
    path: '',
    // canActivate: [AuthGuard],
    // canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty'
    },
    children: [
      { path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule) },
      { path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule) }
    ]
  },



  // Admin routes
  {
    path: '',
    // canActivate: [AuthGuard],
    // canActivateChild: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      //  { path: 'example', loadChildren: () => import('app/modules/admin/example/example.module').then(m => m.ExampleModule) },
      { path: 'profile', canActivate: [KeycloakGuard], loadChildren: () => import('app/profile/profile.module').then(m => m.ProfileModule) },
      { path: "dashboard", canActivate: [KeycloakGuard], component: DashboardComponent },
      // {
      //   path: "inventories", canActivate: [KeycloakGuard], children: [
      //     { path: '', component: InventoriesOverviewComponent },
      //     { path: ':id', component: InventoryComponent, canDeactivate: [ComponentDeactivateCheckGuard] },
      //     { path: 'summary', component: InventoryComponent, canDeactivate: [ComponentDeactivateCheckGuard] },
      //     { path: 'quarterSummary', component: InventoryComponent, canDeactivate: [ComponentDeactivateCheckGuard] },
      //     { path: 'yearSummary', component: InventoriesComponent }
      //   ]
      // },
      {
        path: "inventories", canActivate: [KeycloakGuard],
        loadChildren: () => import('app/bluewin-walmart/my-supplier-view/step2-chemical-inventory/inventory-view.module').then(m => m.InventoryViewModule)


      },

      { path: "supplier-inventories", canActivate: [KeycloakGuard], component: SupplierInventoriesOverviewComponent },
      { path: "step-dashboard", canActivate: [KeycloakGuard], component: StepDashboardComponent },
      { path: "factory-reports", canActivate: [KeycloakGuard], component: FactoryReportingComponent },
      { path: "supplier-reports", canActivate: [KeycloakGuard], component: SupplierReportingComponent },
      { path: "custom-dashboard", canActivate: [KeycloakGuard], component: CustomDashboardComponent },
      { path: "factory-analytics", canActivate: [KeycloakGuard], component: FactoryAnalyticsListComponent },
      { path: "monthly-report", canActivate: [KeycloakGuard], component: MonthlyReportComponent },
      { path: "default-monthly-report", canActivate: [KeycloakGuard], component: DefaultMonthlyReportComponent },
      { path: "c-a-monthly-report", canActivate: [KeycloakGuard], component: CAMonthlyReportComponent },
      { path: "input-inventory", canActivate: [KeycloakGuard], component: InputInventoryComponent },
      { path: "input-inventory/:id", canActivate: [KeycloakGuard], component: InputInventoryComponent },
      { path: "wpu-list", canActivate: [KeycloakGuard], component: WpuListComponent },
      { path: "wet-processing-units", canActivate: [KeycloakGuard], component: WetProcessingUnitComponent },
      { path: "wet-processing-units/:id", canActivate: [KeycloakGuard], component: WetProcessingUnitComponent },
      //{ path: 'profile', canActivate: [KeycloakGuard], component: ProfileComponent },
      { path: 'marketplace', canActivate: [KeycloakGuard], component: MarketplaceComponent },
      { path: 'app-scrivera', canActivate: [KeycloakGuard], component: ScriveraComponent },
      { path: 'app-tox-services-resources', canActivate: [KeycloakGuard], component: ToxServicesResourcesComponent },
      { path: 'documentcenter/:id', canActivate: [KeycloakGuard], component: DocumentcenterComponent },
      { path: 'documentcenter', canActivate: [KeycloakGuard], component: DocumentcenterComponent },
      { path: 'signup', component: UserSignupComponent },
      {
        path: 'gateway', canActivate: [KeycloakGuard],
        loadChildren: () => import('./gateway/gateway.module').then(m => m.GatewayModule)
      },
      {
        path: 'purchase-order',
        loadChildren: () => import('./purchase-order/purchase-order.module').then(m => m.PurchaseOrderModule)
      },
      {
        path: 'chemIq', canActivate: [KeycloakGuard],
        loadChildren: () => import('./chem-iq/chem-iq.module').then(m => m.ChemIqModule)

      },
      {
        path: 'admin', canActivate: [KeycloakGuard],
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
      },
      { path: 'gateway-subscription', canActivate: [KeycloakGuard], component: SubscriptionFormComponent },
      {
        path: 'higg-certificate', canActivate: [KeycloakGuard],
        loadChildren: () => import('./higg-certification/higg-certification.module').then(m => m.HiggCertificationModule)
      },
      {
        path: 'incheck-certificate',
        canActivate: [KeycloakGuard],
        loadChildren: () => import('./in-check-certificate/in-check-certificate.module').then(m => m.InCheckCertificateModule)
      },
      {
        path: 'other-certificate',
        canActivate: [KeycloakGuard],
        loadChildren: () => import('./other-certification/other-certification.module').then(m => m.OtherCertificationModule)
      },
      {
        path: 'chemIq-status', canActivate: [KeycloakGuard],
        loadChildren: () => import('./admin-chem-iq-status/admin-chem-iq-status.module').then(m => m.AdminChemIqStatusModule)
      },
      // {
      //   path: 'supplier-reports', canActivate: [KeycloakGuard],
      //   loadChildren: () => import('./supplier-report/supplier-report.module').then(m => m.SupplierReportModule)
      // },
      {
        path: 'supplier-vendors', canActivate: [KeycloakGuard],
        loadChildren: () => import('./walmart-vendor-list/walmart-vendor-list.module').then(m => m.WalmartVendorListModule)
      },
      {
        path: 'connectivity', canActivate: [KeycloakGuard],
        loadChildren: () => import('./connectivity/connectivity.module').then(m => m.ConnectivityModule)
      },
      { path: 'user-guide-languages', component: UserGuideComponent },

      // {
      //   path: 'sds-document', canActivate: [KeycloakGuard],
      //   loadChildren: () => import('./my-chemstore/my-chemical-list/sds-document/sds-document.module').then(m => m.SdsDocumentModule)
      // },
      {
        path: 'inventory', canActivate: [KeycloakGuard],
        loadChildren: () => import('./inventories/inventories.module').then(m => m.InventoriesModule)
      },
      {
        path: 'factory-analytics', canActivate: [KeycloakGuard],
        loadChildren: () => import('./factory-analytics/factory-analytics.module').then(m => m.FactoryAnalyticsModule)
      },
      {
        path: 'environmental-data', canActivate: [KeycloakGuard],
        loadChildren: () => import('./environmental-data/environmental-data.module').then(m => m.EnvironmentalDataModule)
      },
      {
        path: 'my-chemstore', canActivate: [KeycloakGuard],
        loadChildren: () => import('./my-chemstore/my-chemstore.module').then(m => m.MyChemstoreModule)
      },
      {
        path: 'create-new-inventory', canActivate: [KeycloakGuard],
        loadChildren: () => import('./inventories-overview/copy-inventory-new/copy-inventory-new.module').then(m => m.CopyInventoryNewModule)
      },
      {
        path: 'cas-screener', canActivate: [KeycloakGuard],
        loadChildren: () => import('./cas-screener/cas-screener.module').then(m => m.CasScreenerModule)
      },
      {
        path: 'notification-inbox', canActivate: [KeycloakGuard],
        loadChildren: () => import('./notification-inbox/notification-inbox.module').then(m => m.NotificationInboxModule)
      },
      {
        path: 'new-supplier-view', canActivate: [KeycloakGuard],
        loadChildren: () => import('./bluewin-walmart/bluewin-walmart.module').then(m => m.BluewinWalmartModule)
      },
      {
        path: 'connection-factory', canActivate: [KeycloakGuard],
        loadChildren: () => import('./brand-factory-conn/connection-factory.module').then(m => m.ConnectionFactoryModule)
      },
      {
        path: 'admin-template-management', canActivate: [KeycloakGuard],
        loadChildren: () => import('./admin/master-notification/master-notification.module').then(m => m.MasterNotificationModule)
      },
      {
        path: 'brand-login', canActivate: [KeycloakGuard],
        loadChildren: () => import('./brand-login/brand-login.module').then(m => m.BrandLoginModule)
      },
      {
        path: 'admin-template-management', canActivate: [KeycloakGuard],
        loadChildren: () => import('./admin/master-notification/master-notification.module').then(m => m.MasterNotificationModule)
      },
      {
        path: 'policy-data', canActivate: [KeycloakGuard],
        loadChildren: () => import('./policy-settings/policy-settings.module').then(m => m.PolicySettingsModule)
      },
      {
        path: 'supplier-login', canActivate: [KeycloakGuard],
        loadChildren: () => import('./supplier-login/supplier-login.module').then(m => m.SupplierLoginModule)
      }

    ]
  },


];
