import { LOCALE_ID, AfterViewInit, Component, OnInit, QueryList, ViewChildren, Inject, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { formatDate, formatNumber } from '@angular/common';
import { CubejsService } from '../cubejs/cubejs.service';
import { GlobalsService } from '../../globals-services/globals.service';
import { ComplianceCheckerService } from 'app/compliance-checker/compliance-checker.service';
import { ExportDataService } from '../services/export-data.service';
import { MatDialog } from '@angular/material/dialog';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';


@Component({
  selector: 'app-dashboard-brand',
  templateUrl: './dashboard-brand.component.html',
  styleUrls: ['./dashboard-brand.component.scss']
})
export class DashboardBrandComponent implements OnInit {
  @ViewChild('complianceLevelPaginator') complianceLevelPaginator: MatPaginator;
  @ViewChild('complianceLevelMatSort') complianceLevelMatSort: MatSort;
  @ViewChild('unverifiedMatPaginator') unverifiedMatPaginator: MatPaginator;
  @ViewChild('verifiedMatPaginator') verifiedMatPaginator: MatPaginator;
  @ViewChild('basicMatPaginator') basicMatPaginator: MatPaginator;
  @ViewChild('unverifiedMatSort') unverifiedMatSort: MatSort;
  @ViewChild('verifiedMatSort') verifiedMatSort: MatSort;
  @ViewChild('basicMatSort') basicMatSort: MatSort;
  constructor(private cubejsService: CubejsService, private globalsService: GlobalsService, private exportService: ExportDataService,
    @Inject(LOCALE_ID) public locale: string, private dialog: MatDialog) {
    if (this.isBrandZdhc) {
      this.verifiedTableColumns.push("source")
      this.unverifiedTableColumns.push("source")
      this.basicTableColumns.push("source")
    }
  }
  tabIndex: number = 0;
  errorList = [];
  latestFormulas = [];
  formulaFilters = [];

  //input parameters
  startDate = moment().subtract(5, 'months').startOf('month').format('YYYY-MM-DD');
  endDate = moment().endOf('month').format('YYYY-MM-DD');
  complianceName: string;
  organizationId: string; //must be a string
  // isLoadingfactorycompilancelevel = true;
  // isLoadingunverifiedchemicals = true;
  // isLoadingcompletedfactoryscans = true;
  // isLoadingUnverifiedChemicals: boolean = true;
  // isLoadingVerifiedChemicals: boolean = true;
  // isLoadingBasicChemicals: boolean = true;
  // isLoadingoverallperformance = true;
  // isLoadingcompliancepercountry = true;
  isLoading = false;
  isLoadinggetUnverifiedCAPData = false;
  isLoadinggetVerifiedCAPData = false;
  isLoadinggetBasicCAPData = false;

  isBrandZdhc = GlobalsService.isBrandZdhcMember;
  tableDimensions = this.isBrandZdhc ? [
    "Formulas.organization_name",
    "Formulas.chemical_name",
    "Formulas.chemical_manufacturer",
    "FormulaUser.deadline",
    "FormulaUser.remediation",
    "Formulas.factory_usage",
    "Formulas._type",
    "Formulas.verification_source",
    "Formulas.cap_status"
  ] : [
    "Formulas.organization_name",
    "Formulas.chemical_name",
    "Formulas.chemical_manufacturer",
    "FormulaUser.deadline",
    "FormulaUser.remediation",
    "Formulas.factory_usage",
    "Formulas._type",
    "Formulas.cap_status"
  ];
  //output parameters
  factoryComplianceLevel = ["factory", "compliance_level"]
  unverifiedTableColumns = ["factory", "name", "count", "consumption", "manufacturer", "remediation", "deadline", "usage", "cap_status"]
  verifiedTableColumns = ["factory", "name", "count", "consumption", "manufacturer", "remediation", "deadline", "usage", "cap_status"]
  basicTableColumns = ["factory", "name", "count", "consumption", "manufacturer", "remediation", "deadline", "usage", "cap_status"]

  timeSeriesCompletedFactoryScans: Array<object>
  timeSeriesPerformance: Array<object>
  timeSeriesCompliancePerCountry: Array<object>
  connectedFactories: Number
  factoryComplianceLevelDataList = new MatTableDataSource<any>()
  unverifiedCapFormulaList = new MatTableDataSource<any>()
  verifiedCapFormulaList = new MatTableDataSource<any>()
  basicCapFormulaList = new MatTableDataSource<any>()

  isCubejsConnected: boolean = true;//it is connected to cubejs by default

  view = [(window.innerWidth / 4) + 88, (window.innerHeight / 7) * 2 - 14]
  PeriodAxisTickFormatting(val) {
    return formatDate(val, "yyyy-MM", "EN");
  }
  yAxisTickFormatting(val) {
    return val + '%';
  }
  onResize(event) {
    this.view = [(event.target.innerWidth / 4) + 80, (event.target.innerHeight / 7) * 2 - 14];
  }
  getFontSize = (textLength) => {
    const baseSize = 1.8
    if (textLength >= baseSize) {
      textLength = baseSize - 0.5
    }
    const fontSize = baseSize - textLength
    return `${fontSize}vw`
  }

  ngOnInit(): void {
    this.getComplianceName()
    this.organizationId = this.globalsService.profile["id"].toString();
    this.getFormulasFirst()
    this.getConnectedFactoriesCount()
    this.getTimeSeriesCompletedFactoryScans()
    this.getTimeSeriesPerformance()
  }
  changeKey(originalKey: string, newKey: string, arr: Array<Object>) {
    var newArr = [];
    for (var i = 0; i < arr.length; i++) {
      var obj = arr[i];
      obj[newKey] = obj[originalKey];
      delete (obj[originalKey]);
      newArr.push(obj);
    }
    return newArr;
  }
  ShowErrorMessage(msg) {
    this.isLoading = false;
    let customMSg = false;
    if (msg.includes("No id found in Security Context")) {
      msg = 'No factories are connected to this account, so no data can be displayed.\n Ask your factories to connect to you to see their data.',
        customMSg = true;
    }
    if (msg.includes("Network request failed")) {
      this.isCubejsConnected = false;
      throw new Error("CubeJS is not reachable!")
      // return false;
    }
    if (!this.errorList.includes(msg)) {
      this.errorList.push(msg);
      this.dialog.open(MessageDialogComponent, {
        hasBackdrop: true,
        width: "32rem",
        disableClose: true,
        data: {
          message: msg,
          hasLink: customMSg ? true : false,
          goToLink: customMSg ? "How to share inventories" : "",
          URL: customMSg ? "https://cloud.goblu.net/s/4LXyFHrE86Tzx2A" : ""
        },
      });
      // alert(msg);
    }
  }
  getFormulasFirst() {
    let query = {
      "dimensions": [
        "Formulas.organization_id",
        "Formulas.chemical_identifier"
      ],
      "timeDimensions": [
        {
          "dimension": "Formulas.scan_date"
        }
      ],
      "order": [
        [
          "Formulas.chemical_identifier",
          "asc"
        ]
      ],
      "filters": [
        {
          "member": "Formulas.scan_date",
          "operator": "inDateRange",
          "values": [
            this.startDate,
            this.endDate
          ]
        }
      ],
      "measures": [
        "Formulas.latest_formula_in_range"
      ]
    };
    this.cubejsService.getCubeJSData(query, null, null, true, false)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        tempData = this.changeKey("Formulas.latest_formula_in_range", "latest_formula", tempData);

        this.latestFormulas = tempData.map(function (el) { return el.latest_formula; });
        for (let obj of this.latestFormulas) {
          this.formulaFilters.push(obj.toString());
        }
        this.getFactoryComplianceLevelData()
        this.onTabChanged()
        this.getTimeSeriesCompliancePerCountry()
      })
  }

  getComplianceName() {
    const query = {
      "dimensions": [
        "ComplianceName.compliance_name"
      ],
      "timeDimensions": [],
      "order": {},
      "measures": [],
      "filters": []
    }

    this.cubejsService.getCubeJSData(query, null, null, true, false).then(data => {
      data = data['loadResponse']['results'][0]['data'];
      this.complianceName = data[0]['ComplianceName.compliance_name']
    })
      .catch(error => {
        this.ShowErrorMessage(error.message);
      })
  }
  getConnectedFactoriesCount() {
    const query = {
      "measures": [
        "BrandConnections.total_count"
      ],
      "timeDimensions": [],
      "order": {},
      "dimensions": [],
      "filters": []
    }

    this.cubejsService.getCubeJSData(query, null, null, true, false).then(data => {
      data = data['loadResponse']['results'][0]['data'];
      this.connectedFactories = data[0]['BrandConnections.total_count']
    })
      .catch(error => {
        this.ShowErrorMessage(error.message);
      })
  }
  getTimeSeriesPerformance() {
    this.isLoading = true;
    const query = {
      "measures": [
        "Formulas.brand_compliant_pct"
      ],
      "timeDimensions": [
        {
          "dimension": "Formulas.scan_date",
          "granularity": "month",
          "dateRange": [
            this.startDate,
            this.endDate
          ]
        }
      ],
      "order": {},
      "dimensions": [
        "Formulas.year_month_scan_date"
      ]
    }

    this.cubejsService.getCubeJSData(query, null, null, true, false)
      .then(data => {
        this.timeSeriesPerformance = data['loadResponse']['results'][0]['data'];
        // this.isLoadingoverallperformance = false;
        this.isLoading = false;
        this.timeSeriesPerformance.forEach(el => el["Formulas.brand_compliant_pct"] = formatNumber(el["Formulas.brand_compliant_pct"], this.locale, '2.1-2'))
      })
      .then(() => this.timeSeriesPerformance = this.changeKey("Formulas.year_month_scan_date", "name", this.timeSeriesPerformance))
      .then(() => this.timeSeriesPerformance = this.changeKey("Formulas.brand_compliant_pct", "value", this.timeSeriesPerformance))
      .catch(error => {
        this.ShowErrorMessage(error.message);
      })
  }
  getFactoryComplianceLevelData() {
    this.isLoading = true;
    const query = {
      "dimensions": [
        "Formulas.organization_name"
      ],
      "timeDimensions": [
        {
          "dimension": "Formulas.scan_date"
        }
      ],
      "order": {
        "Formulas.factory_compliant_pct": "desc"
      },
      "measures": [
        "Formulas.factory_compliant_pct"
      ],
      "filters": [
        {
          "and": [
            {

              "member": "Formulas.scan_date",
              "operator": "inDateRange",
              "values": [
                this.startDate,
                this.endDate
              ]

            },
            {
              "or": this.formulaFilters.length == 0 ? [] : [{
                "member": 'Formulas.id',
                "operator": 'equals',
                "values": this.formulaFilters
              }]
            }
          ]
        }
      ]
    }

    this.cubejsService.getCubeJSData(query, null, null, true, false)
      .then(data => {
        let result = data['loadResponse']['results'][0]['data'];
        result = this.changeKey("Formulas.organization_name", "factory", result)
        result = this.changeKey("Formulas.factory_compliant_pct", "compliance_level", result)
        this.factoryComplianceLevelDataList.data = result;
        // this.isLoadingfactorycompilancelevel = false; 
        this.isLoading = false;
        this.factoryComplianceLevelDataList.paginator = this.complianceLevelPaginator;
        this.factoryComplianceLevelDataList.sort = this.complianceLevelMatSort;
      })
      .catch(error => {
        this.ShowErrorMessage(error.message);
      })

  }

  selectedTabChanged($event) {
    this.tabIndex = $event.index;
    this.onTabChanged()
  }
  onTabChanged() {
    // this.isLoading = true;
    switch (this.tabIndex) {
      case 0: {
        this.loadPage(0, this.pageSize, 'getUnverifiedCAPData');
        break;
      }
      case 1: {
        this.loadPage(0, this.pageSize, 'getVerifiedCAPData');

        break;
      }
      case 2: {
        this.loadPage(0, this.pageSize, 'getBasicCAPData');

        break;
      }
    }
  }

  getUnverifiedCAPData(limit: any, offset: any) {
    this.isLoading = true;

    const query = {
      "measures": [
        "Formulas.total_count",
        "Formulas.total_consumption"
      ],
      "timeDimensions": [
        {
          "dimension": "Formulas.scan_date"
        }
      ],
      "order": {
        "Formulas.total_count": "desc"
      },
      "segments": [
        "Formulas.non_compliant_cr",
      ],
      "dimensions": this.tableDimensions,
      "filters": [
        {
          "and": [
            {

              "member": "Formulas.scan_date",
              "operator": "inDateRange",
              "values": [
                this.startDate,
                this.endDate
              ]

            },
            {
              "or": this.formulaFilters.length == 0 ? [] : [{
                "member": 'Formulas.id',
                "operator": 'equals',
                "values": this.formulaFilters
              }]
            }
          ]
        }
      ],
      limit: limit,
      offset: offset,
      total: true
    }

    this.cubejsService.getCubeJSData(query, null, null, true, false)
      .then(data => {
        let result = data['loadResponse']['results'][0]['data'];
        result = this.changeKey("Formulas.organization_name", "factory", result);
        result = this.changeKey("Formulas.chemical_name", "name", result);
        result = this.changeKey("Formulas.total_count", "count", result);
        result = this.changeKey("Formulas.total_consumption", "consumption", result);
        result = this.changeKey("Formulas.chemical_manufacturer", "manufacturer", result);
        result = this.changeKey("FormulaUser.remediation", "remediation", result);
        result = this.changeKey("FormulaUser.deadline", "deadline", result);
        result = this.changeKey("Formulas.factory_usage", "usage", result);
        result = this.changeKey("Formulas._type", "type", result);
        result = this.isBrandZdhc ? this.changeKey("Formulas.verification_source", "source", result) : result;
        result = this.changeKey("Formulas.cap_status", "cap_status", result);
        this.unverifiedCapFormulaList.data = result;
        // this.isLoadingUnverifiedChemicals = false;
        this.isLoading = false;
        this.totalItemsUnverifiedCAPData = data['loadResponse']['results'][0]['total'] || 0;
        // this.unverifiedCapFormulaList.paginator = this.unverifiedMatPaginator;
        this.unverifiedCapFormulaList.sort = this.unverifiedMatSort;
      })
      .catch(error => {
        this.ShowErrorMessage(error.message);
        this.isLoading = false;
      })
  }

  getVerifiedCAPData(limit: any, offset: any) {
    this.isLoading = true;
    const query = {
      "measures": [
        "Formulas.total_count",
        "Formulas.total_consumption"
      ],
      "timeDimensions": [
        {
          "dimension": "Formulas.scan_date"
        }
      ],
      "segments": [
        "Formulas.compliant_cr",
      ],
      "order": {
        "Formulas.total_count": "desc"
      },
      "dimensions": this.tableDimensions,
      "filters": [
        {
          "and": [
            {

              "member": "Formulas.scan_date",
              "operator": "inDateRange",
              "values": [
                this.startDate,
                this.endDate
              ]

            },
            {
              "or": this.formulaFilters.length == 0 ? [] : [{
                "member": 'Formulas.id',
                "operator": 'equals',
                "values": this.formulaFilters
              }]
            }
          ]
        }
      ],
      limit: limit,
      offset: offset,
      total: true
    }

    this.cubejsService.getCubeJSData(query, null, null, true, false)
      .then(data => {
        let result = data['loadResponse']['results'][0]['data'];
        result = this.changeKey("Formulas.organization_name", "factory", result);
        result = this.changeKey("Formulas.chemical_name", "name", result);
        result = this.changeKey("Formulas.total_count", "count", result);
        result = this.changeKey("Formulas.total_consumption", "consumption", result);
        result = this.changeKey("Formulas.chemical_manufacturer", "manufacturer", result);
        result = this.changeKey("FormulaUser.remediation", "remediation", result);
        result = this.changeKey("FormulaUser.deadline", "deadline", result);
        result = this.changeKey("Formulas.factory_usage", "usage", result);
        result = this.changeKey("Formulas._type", "type", result);
        result = this.isBrandZdhc ? this.changeKey("Formulas.verification_source", "source", result) : result;
        result = this.changeKey("Formulas.cap_status", "cap_status", result);
        this.verifiedCapFormulaList.data = result;
        this.totalItemsVerifiedCAPData = data['loadResponse']['results'][0]['total'] || 0;
        // this.isLoadingVerifiedChemicals = false;
        this.isLoading = false;
        // this.verifiedCapFormulaList.paginator = this.verifiedMatPaginator;
        this.verifiedCapFormulaList.sort = this.verifiedMatSort;
      })
      .catch(error => {
        this.ShowErrorMessage(error.message);
        this.isLoading = false;
      })
  }

  getBasicCAPData(limit: any, offset: any) {
    this.isLoading = true;
    const query = {
      "measures": [
        "Formulas.total_count",
        "Formulas.total_consumption"
      ],
      "timeDimensions": [
        {
          "dimension": "Formulas.scan_date"
        }
      ],
      "order": {
        "Formulas.total_count": "desc"
      },
      "segments": [
        "Formulas.basic_chemical_cr",
      ],
      "dimensions": this.tableDimensions,
      "filters": [
        {
          "and": [
            {

              "member": "Formulas.scan_date",
              "operator": "inDateRange",
              "values": [
                this.startDate,
                this.endDate
              ]

            },
            {
              "or": this.formulaFilters.length == 0 ? [] : [{
                "member": 'Formulas.id',
                "operator": 'equals',
                "values": this.formulaFilters
              }]
            }
          ]
        }
      ],
      limit: limit,
      offset: offset,
      total: true
    }

    this.cubejsService.getCubeJSData(query, null, null, true, false)
      .then(data => {
        let result = data['loadResponse']['results'][0]['data'];
        result = this.changeKey("Formulas.organization_name", "factory", result);
        result = this.changeKey("Formulas.chemical_name", "name", result);
        result = this.changeKey("Formulas.total_count", "count", result);
        result = this.changeKey("Formulas.total_consumption", "consumption", result);
        result = this.changeKey("Formulas.chemical_manufacturer", "manufacturer", result);
        result = this.changeKey("FormulaUser.remediation", "remediation", result);
        result = this.changeKey("FormulaUser.deadline", "deadline", result);
        result = this.changeKey("Formulas.factory_usage", "usage", result);
        result = this.changeKey("Formulas._type", "type", result);
        result = this.isBrandZdhc ? this.changeKey("Formulas.verification_source", "source", result) : result;
        result = this.changeKey("Formulas.cap_status", "cap_status", result);
        this.basicCapFormulaList.data = result;
        // this.isLoadingBasicChemicals = false;
        this.totalItemsBasicCAPData = data['loadResponse']['results'][0]['total'] || 0;
        this.isLoading = false;
        // this.basicCapFormulaList.paginator = this.basicMatPaginator;
        this.basicCapFormulaList.sort = this.basicMatSort;
      })
      .catch(error => {
        this.ShowErrorMessage(error.message);
        this.isLoading = false;
      })
  }

  getTimeSeriesCompletedFactoryScans() {
    this.isLoading = true;
    const query = {
      "measures": [
        "Formulas.confirmation_of_completeness_cnt"
      ],
      "timeDimensions": [
        {
          "dimension": "Formulas.scan_date",
          "granularity": "month"
        }
      ],
      "order": {},
      "filters": [
        {
          "member": "Formulas.scan_date",
          "operator": "inDateRange",
          "values": [
            this.startDate,
            this.endDate
          ]
        }
      ],
      "dimensions": []
    }

    this.cubejsService.getCubeJSData(query, null, null, true, false)
      .then(data => {
        this.timeSeriesCompletedFactoryScans = data['loadResponse']['results'][0]['data'];
        // this.isLoadingcompletedfactoryscans = false
        this.isLoading = false;
      })
      .then(() => this.timeSeriesCompletedFactoryScans = this.changeKey("Formulas.scan_date", "name", this.timeSeriesCompletedFactoryScans))
      .then(() => this.timeSeriesCompletedFactoryScans = this.changeKey("Formulas.confirmation_of_completeness_cnt", "value", this.timeSeriesCompletedFactoryScans))
      .catch(error => {
        this.ShowErrorMessage(error.message);
      })

  }

  getTimeSeriesCompliancePerCountry() {
    this.isLoading = true;
    const query = {
      "measures": [
        "Formulas.brand_compliant_pct"
      ],
      "timeDimensions": [
        {
          "dimension": "Formulas.scan_date"
        }
      ],
      "order": {
        "Formulas.brand_compliant_pct": "desc"
      },
      "filters": [
        {
          "and": [
            {

              "member": "Formulas.scan_date",
              "operator": "inDateRange",
              "values": [
                this.startDate,
                this.endDate
              ]

            },
            {
              "or": this.formulaFilters.length == 0 ? [] : [{
                "member": 'Formulas.id',
                "operator": 'equals',
                "values": this.formulaFilters
              }]
            }
          ]
        }
      ],
      "dimensions": [
        "Factories.country_name"
      ]
    }

    this.cubejsService.getCubeJSData(query, null, null, true, false)
      .then(data => {
        this.timeSeriesCompliancePerCountry = data['loadResponse']['results'][0]['data'];
        // this.isLoadingcompliancepercountry = false;
        this.isLoading = false;
        this.timeSeriesCompliancePerCountry.forEach(el => el["Formulas.brand_compliant_pct"] = formatNumber(el["Formulas.brand_compliant_pct"], this.locale, '2.1-2'))
      })
      .then(() => this.timeSeriesCompliancePerCountry = this.changeKey("Factories.country_name", "name", this.timeSeriesCompliancePerCountry))
      .then(() => this.timeSeriesCompliancePerCountry = this.changeKey("Formulas.brand_compliant_pct", "value", this.timeSeriesCompliancePerCountry))
      .catch(error => {
        this.ShowErrorMessage(error.message);
      })
  }


  exportData(data: any, fileName: string): void {
      this.exportService.exportAsExcelFile(data, fileName);
    
  }

  async fetchAllData(tabIndex: number) {
    let allData = [];
    let page = 0; // Start at the first page
    const pageSize = 1000; // Number of records per page (adjust as needed)
    let hasMoreData = true;
    let query: any;
    let queryName: string;
    while (hasMoreData) {
        switch (tabIndex) {
          case 0: {
            query = {
              "measures": [
                "Formulas.total_count",
                "Formulas.total_consumption"
              ],
              "timeDimensions": [
                {
                  "dimension": "Formulas.scan_date"
                }
              ],
              "order": {
                "Formulas.total_count": "desc"
              },
              "segments": [
                "Formulas.non_compliant_cr",
              ],
              "dimensions": this.tableDimensions,
              "filters": [
                {
                  "and": [
                    {

                      "member": "Formulas.scan_date",
                      "operator": "inDateRange",
                      "values": [
                        this.startDate,
                        this.endDate
                      ]

                    },
                    {
                      "or": this.formulaFilters.length == 0 ? [] : [{
                        "member": 'Formulas.id',
                        "operator": 'equals',
                        "values": this.formulaFilters
                      }]
                    }
                  ]
                }
              ],
              limit: pageSize,
              offset: page * pageSize
            }


            queryName = "UnverifiedChemicals";
          break;
        }
          case 1: {
            query = {
              "measures": [
                "Formulas.total_count",
                "Formulas.total_consumption"
              ],
              "timeDimensions": [
                {
                  "dimension": "Formulas.scan_date"
                }
              ],
              "segments": [
                "Formulas.compliant_cr",
              ],
              "order": {
                "Formulas.total_count": "desc"
              },
              "dimensions": this.tableDimensions,
              "filters": [
                {
                  "and": [
                    {

                      "member": "Formulas.scan_date",
                      "operator": "inDateRange",
                      "values": [
                        this.startDate,
                        this.endDate
                      ]

                    },
                    {
                      "or": this.formulaFilters.length == 0 ? [] : [{
                        "member": 'Formulas.id',
                        "operator": 'equals',
                        "values": this.formulaFilters
                      }]
                    }
                  ]
                }
              ],
              limit: pageSize,
              offset: page * pageSize
            }

            queryName = "VerifiedChemicals";
          break;
        };
          case 2: {
            query = {
              "measures": [
                "Formulas.total_count",
                "Formulas.total_consumption"
              ],
              "timeDimensions": [
                {
                  "dimension": "Formulas.scan_date"
                }
              ],
              "order": {
                "Formulas.total_count": "desc"
              },
              "segments": [
                "Formulas.basic_chemical_cr",
              ],
              "dimensions": this.tableDimensions,
              "filters": [
                {
                  "and": [
                    {

                      "member": "Formulas.scan_date",
                      "operator": "inDateRange",
                      "values": [
                        this.startDate,
                        this.endDate
                      ]

                    },
                    {
                      "or": this.formulaFilters.length == 0 ? [] : [{
                        "member": 'Formulas.id',
                        "operator": 'equals',
                        "values": this.formulaFilters
                      }]
                    }
                  ]
                }
              ],
              limit: pageSize,
              offset: page * pageSize
            }

            queryName = "CommodityChemicals";
          break;
        }
           
      }
      const response = await this.cubejsService.getCubeJSData(query, null, null, true, false);
      const data = response['loadResponse']['results'][0]['data'];

      allData = allData.concat(data);

      hasMoreData = data.length >= pageSize;
      page++;
    }
      this.exportService.exportAsExcelFile(allData, queryName)
  }
  
  pageSize = 10;
  totalItemsUnverifiedCAPData = 0;
  totalItemsVerifiedCAPData = 0;
  totalItemsBasicCAPData = 0;
  loadPage(offset: number, limit: number, queryName: string) {
    this.isLoading = true;
    this[queryName](limit, offset);
  }

  onPaginateChange(event: any, queryName: any) {
    const offset = event.pageIndex * event.pageSize;
    const limit = event.pageSize;
    this.loadPage(offset, limit, queryName);
  }
}