import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastService } from 'app/common/toast-alert/toast.service';
import { OrganizationType } from 'app/globals-classes/OrganizationType';
import moment from 'moment';

import { GlobalsService } from 'app/globals-services/globals.service';
import { Workbook } from 'exceljs';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import * as fs from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_EXTENSION = '.xlsx';
@Injectable({
  providedIn: 'root'
})
export class ExportDataService {
  isSupplier: boolean = GlobalsService.userRole.organizationType == OrganizationType.SUPPLIER;

  constructor(private toastService: ToastService,
    private httpClient: HttpClient
  ) { }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const myworkbook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(myworkbook, myworksheet, 'Sheet1');
    XLSX.writeFile(myworkbook, excelFileName + EXCEL_EXTENSION, { bookType: 'xlsx', type: 'file' });
  }

  public exportMonthlyReport(json: any[], excelFileName: string, organization_name: string): void {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(excelFileName);
    let headerRow = [];
    if (GlobalsService.isAldi) {
      headerRow = ["Factory Name", "ALDI ID", "Date of inventory", "Total Number of Chemicals", "Consumption", "Total Production Facility", "ALDI Group Production"];
    } else if (GlobalsService.isAldiImporterBrand) {
      headerRow = ["Organization ID", "Factory Name", "Brand ID", "Date of inventory", "Total Number of Chemicals", "Consumption", "Total Production Facility", "ALDI Group Number of chemicals", "ALDI Group Production", "Brand Chemical Count", "Brand Production", "Connected to Aldi"];
    } else if (GlobalsService.isAldiSourcing) {
      headerRow = ["Factory Name", "email", "Country", "Last Scan Date", "Has Uploaded Last Month", "Gateway Subscription", "Connected to ALDI Sourcing", "Connected to ALDI Einkauf SE & Co. oHG", "Connected to Aldi Süd KG", "# of chemicals uploaded for ALDI Sourcing", "# of chemicals uploaded for ALDI Group Production", "Total Chemicals Uploaded"];
    } else if (GlobalsService.isHofer) {
      headerRow = ["Factory Name", "Aldi Süd KG ID", "Date of inventory", "Aldi Süd KG Number of chemical", "Consumption", "Total Production Facility", "Aldi Süd KG Production"];
    } else if (GlobalsService.isInditex) {
      headerRow = ["CIL Supplier", "Factory Name", "Factory Country", "ZDHC AID", "Reporting month/year", "Chemical supplier name", "Chemical Supplier Country", "Chemical product", "ZDHC PID Product", "ZDHC Substrate", "ZDHC Use category", "ZDHC Use type", "Type of manufacturing", "Consumption (kg)", "ZDHC Certification Level", "ZDHC MRSL by BHive Crowdource", "ZDHC MRSL Source by BHive Crowdource", "ZDHC MRSL Level by BHive Crowdource", "'The List by Inditex' certification level (Baby)", "'The List by Inditex' certification level (Adult)", "Chemical substance", "Maximum Concentration of Substance %", "CAS Number", "EC Number", "Toxicity ECHA", "Toxicity SVHC", "Toxicity other regulatory list"];
    } else {
      headerRow = ["ZDHC AID", "Factory Name", "Contact Person", "Email", "Phone", "Vendor ID", "Higg ID", "Mill ID", "Activity", "Country Name", "Inventory Date", "Nr of Chemicals", "Cap for Chemicals", "Compliance", "Has Uploaded Wastewater", "Has Uploaded Incheck", "Has Uploaded Higg", "Last Upload Date"];
    }
    if (GlobalsService.isHm) {
      const headerCells = worksheet.addRow([]);
      worksheet.mergeCells('A1:E1');
      worksheet.getCell('A1').value = 'Basic Information';
      this.setCellStyle(worksheet.getCell('A1'));


      worksheet.getCell('F1').value = 'BHive database information';
      this.setCellStyle(worksheet.getCell('F1'));


      worksheet.mergeCells('G1:T1');
      worksheet.getCell('G1').value = 'Compliance information';
      this.setCellStyle(worksheet.getCell('G1'));


      worksheet.mergeCells('U1:W1');
      worksheet.getCell('U1').value = 'SCOPE: Usage / Scope information';
      this.setCellStyle(worksheet.getCell('U1'));


      worksheet.mergeCells('X1:AA1');
      worksheet.getCell('X1').value = 'QUANTITY: Usage / Delivery Information';
      this.setCellStyle(worksheet.getCell('X1'));


      worksheet.mergeCells('AB1:AE1');
      worksheet.getCell('AB1').value = 'Batch information';
      this.setCellStyle(worksheet.getCell('AB1'));


      worksheet.mergeCells('AF1:AP1');
      worksheet.getCell('AF1').value = 'SDS Information';
      this.setCellStyle(worksheet.getCell('AF1'));


      const subtitleRow = [
        'Chemical No.', 'Factory Name', 'Date of Scan', 'Name of Chemical (as added in BHive CIL)', 'Chemical Manufacturer Name (as added in BHive CIL)',
        'Manually added / BHive Database formula', 'Name of Chemical matched in ZDHC Gateway', 'Name of Chemical Manufacturer matched in ZDHC Gateway',
        'ZDHC MRSL (Gateway + crowdsourced)', 'Gateway Level', 'ZDHC MRSL (crowdsourced - BHive database)', 'Gots', 'Gots Version', 'Eco Passport', 'Bluesign',
        'Inditex BelowThreeYears', 'Inditex AboveThreeYears', 'Toxfmd', 'Scivera', 'Test Report', 'Chemical is in scope for InCheck', 'General Usage indicated by factory',
        'Category', 'Marked as "Used for H&M"', 'Consumption (kg)', 'Ending Stock (kg)', 'Delivered Quantity (kg)', 'Manufacture Date', 'Purchase Date', 'Expiry Date',
        'Lot Number', 'Cas Nr', 'Notes', 'Use of PPE', 'Ghs Msds Available', 'Precautionary Statement', 'Ghs Hazard Statement', 'Ghs Classification',
        'Stock Location', 'Storage Condition', 'Minimum Stock', 'Maximum Stock'
      ];

      const subtitleCells = worksheet.addRow(subtitleRow);
      subtitleCells.eachCell((cell) => {
        cell.font = { bold: true };
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'F2F2F2' },
        };
        cell.alignment = { vertical: 'middle', horizontal: 'center' };
      });
    }
    else {
      const headerCells = worksheet.addRow(headerRow);
      headerCells.eachCell((cell) => {
        cell.font = { bold: true };
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'D9D9D9' },
        };
        cell.alignment = { vertical: 'middle', horizontal: 'center' };
      });
    }
    if (GlobalsService.isAldiImporterBrand) {
      const subtitleRow = [
        "Bhive ID",
        "Factory Name as registered in The BHive",
        "ALDI Internal ID",
        "Month of Scan",
        "Total Number of all chemicals disregarding ALDI / " + organization_name + " production (based on ZDHC InCheck Scope)",
        "",
        "ZDHC MRSL compliance percentage (based on ZDHC InCheck Scope) of all chemicals disregarding ALDI / " + organization_name + " production",
        "Number of chemicals marked as 'Used for ALDI Group' (based on ZDHC InCheck Scope)",
        "ZDHC MRSL compliance percentage (based on ZDHC InCheck Scope) of all chemicals marked as 'Used for ALDI Group' Note: if the field is empty, no chemicals were marked as 'Used for ALDI Group' for this month",
        "Number of chemicals marked as Used for " + organization_name + " (based on ZDHC InCheck Scope)<br>This information is currently not shared with ALDI",
        "ZDHC MRSL compliance percentage (based on ZDHC InCheck Scope) of all chemicals marked as 'Used for " + organization_name + "' Note: if the field is empty, no chemicals were marked as 'Used for " + organization_name + "' for this month. This information is currently not shared with ALDI",
        "The supplier is connected to ALDI"
      ];
      const subtitleCells = worksheet.addRow(subtitleRow);
      subtitleCells.eachCell((cell) => {
        cell.font = { bold: true };
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'F2F2F2' },
        };
        cell.alignment = { vertical: 'middle', horizontal: 'center' };
      });
      worksheet.getCell('I2').value = {
        richText: [
          {
            text: "ZDHC MRSL compliance percentage (based on ZDHC InCheck Scope) of all chemicals marked as 'Used for ALDI Group' ",
          },
          {
            text: "Note: if the field is empty, no chemicals were marked as 'Used for ALDI Group' for this month",
            font: {
              italic: true,
              color: {
                argb: '00FF0000',
                theme: 1,

              },
            },
          },
        ],
      };
      worksheet.getCell('J2').value = {
        richText: [
          {
            text: "Number of chemicals marked as 'Used for" + organization_name + "'(based on ZDHC InCheck Scope) ",
          },
          {
            text: "--> This information is currently not shared with ALDI ",
            font: {
              italic: true,
              color: {
                argb: '00FF0000',
                theme: 1,
              },
            },
          },
        ],
      };
      worksheet.getCell('K2').value = {
        richText: [
          {
            text: "ZDHC MRSL compliance percentage (based on ZDHC InCheck Scope) of all chemicals marked as 'Used for " + organization_name + "' ",
          },
          {
            text: "Note: if the field is empty , no chemicals were marked as 'Used for " + organization_name + "' for this month ",
            font: {
              italic: true,
              color: {
                argb: 'black',
                theme: 1,
              },
            },
          },
          {
            text: "--> This information is currently not shared with ALDI ",
            font: {
              italic: true,
              color: {
                argb: '00FF0000',
                theme: 1,
              },
            },
          },
        ],
      };
    }
    const columnWidth = 50; // Adjust the width as needed
    worksheet.columns.forEach(column => {
      column.width = columnWidth;
    });

    json.forEach((d: any) => {
      let row = worksheet.addRow(Object.values(d));
    });

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, excelFileName + EXCEL_EXTENSION);
    });
  }

  public exportDefaultMonthlyReport(json: any[], excelFileName: string, organization_name: string, isBrandZdhc: Boolean): void {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(excelFileName);
    worksheet.addRow([]);
    worksheet.addRow([]);
    worksheet.getCell('B2').value = 'Reporting Date [report generated on]:';
    worksheet.getCell('B2').font = { bold: true };
    worksheet.getCell('C2').value = "Date of Download / Today's Date";
    worksheet.getCell('C2').font = { bold: true };
    worksheet.addRow([]);
    worksheet.getCell('B3').value = `${month}-${day}-${year}`;
    worksheet.getCell('B3').font = { bold: true };
    worksheet.getCell('C3').value = `${month}-${day}-${year}`;
    worksheet.getCell('C3').font = { bold: true };
    worksheet.addRow([]);
    worksheet.addRow([]);
    worksheet.mergeCells('A5:E5');
    worksheet.getCell('A5').value = 'Factory Information';
    worksheet.getCell('A5').font = { bold: true };
    worksheet.getCell('A5').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'D9D9D9' },
    };
    worksheet.getCell('A5').alignment = { vertical: 'middle', horizontal: 'center' };

    worksheet.getCell('F5').value = 'SCOPE';
    worksheet.getCell('F5').font = { bold: true };
    worksheet.getCell('F5').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'D9D9D9' },
    };
    worksheet.getCell('F5').alignment = { vertical: 'middle', horizontal: 'center' };

    worksheet.getCell('G5').value = 'Connection Status';
    worksheet.getCell('G5').font = { bold: true };
    worksheet.getCell('G5').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'D9D9D9' },
    };
    worksheet.getCell('G5').alignment = { vertical: 'middle', horizontal: 'center' };

    worksheet.mergeCells(isBrandZdhc ? 'H5:Q5' : 'H5:N5');
    worksheet.getCell('H5').value = 'INPUT';
    worksheet.getCell('H5').font = { bold: true };
    worksheet.getCell('H5').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'B4DBE7' },
    };
    worksheet.getCell('H5').alignment = { vertical: 'middle', horizontal: 'center' };

    worksheet.getCell(isBrandZdhc ? 'R5' : 'O5').value = '';
    worksheet.getCell(isBrandZdhc ? 'R5' : 'O5').font = { bold: true };
    worksheet.getCell(isBrandZdhc ? 'R5' : 'O5').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'B4DBE7' },
    };
    worksheet.getCell(isBrandZdhc ? 'R5' : 'O5').alignment = { vertical: 'middle', horizontal: 'center' };

    // worksheet.mergeCells(isBrandZdhc ? 'S5:V5' : 'P5:S5');
    // worksheet.getCell(isBrandZdhc ? 'S5' : 'P5').value = 'OUTPUT';
    // worksheet.getCell(isBrandZdhc ? 'S5' : 'P5').font = { bold: true };
    // worksheet.getCell(isBrandZdhc ? 'S5' : 'P5').fill = {
    //   type: 'pattern',
    //   pattern: 'solid',
    //   fgColor: { argb: '5E6D91' },
    // };
    // worksheet.getCell(isBrandZdhc ? 'S5' : 'P5').alignment = { vertical: 'middle', horizontal: 'center' };

    const subtitleRow = isBrandZdhc ? ["ORG_ID", "Factory Name", "Country", "Email", "Supplier Ids", "In Scope", "Connected to " + organization_name, "Scanned 2 Months Ago", "Created InCheck Report 2 Months Ago", "Performance % Two Months ago", "Scanned in Last Month", "Created InCheck Report Last Month", "Performance % Last Month", "Scanned Current Month", "Created InCheck Report Current Month", "Performance % Current Month", "Scanned At Least Once this Quarter", "Last Quarter Performance"]
      : ["ORG_ID", "Factory Name", "Country", "Email", "Supplier Ids", "In Scope", "Connected to " + organization_name, "Scanned 2 Months Ago", "Performance % Two Months ago", "Scanned in Last Month", "Performance % Last Month", "Scanned Current Month", "Performance % Current Month", "Scanned At Least Once this Quarter", "Last Quarter Performance" ] ;

    const subtitleCells = worksheet.addRow(subtitleRow);
    subtitleCells.eachCell((cell) => {
      cell.font = { bold: true };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'F3F3F5' },
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
    });

  const columnWidth = 50;
  worksheet.columns.forEach(column => {
    column.width = columnWidth;
  });
  json.forEach((d: any) => {
    let row = worksheet.addRow(Object.values(d));
  });
    worksheet.eachRow((row, rowNumber) => {
      if (rowNumber > 6) { // Skip the header rows
        row.eachCell((cell) => {
          const value = cell.value.toString();
          const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
          if (dateRegex.test(value) && !isNaN(Date.parse(value))) {
            const cellDate = moment(value, 'YYYY-MM-DD');
            const currentDate = moment();

            const cellStyle = this.getCellStyle(cellDate, currentDate);
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: cellStyle.backgroundColor },
            };
            cell.font = {
              color: { argb: cellStyle.textColor },
            };
          }

          if (value === 'Yes' || value === 'Uploaded' || value === 'Aspirational' || value === 'Passed' || value === 'Not Applicable') {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'C6F0CE' }, // Green
            };
            cell.font = {
              color: { argb: '2A611A' } // Dark Green text color
            };
          } else if (value === 'No' || value === 'Not Connected' || value === 'No upload' || value === 'No result') {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'F6C9CE' }, // Red
            };
            cell.font = {
              color: { argb: 'CB7D81' } // Dark Red text color
            };
          }
        });
      }
    });

  workbook.xlsx.writeBuffer().then((data) => {
    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    fs.saveAs(blob, excelFileName + EXCEL_EXTENSION);
  });
  }

   getCellStyle(cellDate: moment.Moment, currentDate: moment.Moment): { backgroundColor: string; textColor: string } {
    if (cellDate.isSame(currentDate, 'month') || cellDate.isSame(currentDate.clone().add(1, 'month'), 'month')) {
      return { backgroundColor: 'FCECA6', textColor: '9F6C2A' };
    } else if (cellDate.isBefore(currentDate, 'month')) {
      return { backgroundColor: 'F6C9CE', textColor: 'CB7D81' };
    } else if (cellDate.isAfter(currentDate.clone().add(1, 'month'), 'month')) {
      return { backgroundColor: 'C6F0CE', textColor: '2A611A' };
    }
    return { backgroundColor: 'transparent', textColor: 'black' };
  }
  setCellStyle(cell) {
    cell.font = { bold: true };
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'D9D9D9' },
    };
    cell.alignment = { vertical: 'middle', horizontal: 'center' };
    cell.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
  }
  public exportCandAasExcelFile(json1, json2, json3, json4, json5, json6, excelFileName) {
    const workbook = new Workbook();

    const addWorksheet = (titles, tables, title, customHeadersList) => {
      const worksheet = workbook.addWorksheet(title);

      for (let i = 0; i < tables.length; i++) {
        const customHeaders = customHeadersList[i];
        const data = tables[i];
        if (titles.length > 0) {
          let titleRow = worksheet.addRow([titles[i]]);
          titleRow.eachCell(cell => {
            cell.font = { bold: true, size: 16 };
          });
        }
        let headerRow = worksheet.addRow(customHeaders);
        headerRow.eachCell(cell => {
          cell.font = { bold: true, size: 12 };
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'F2F2F2' },
            bgColor: { argb: '' }
          };
        });

        data.forEach((d: any) => {
          worksheet.addRow(Object.values(d));
        });

        worksheet.addRow([])
          ; // Add an empty row between tables
      }
      const columnWidth = 50; // Adjust the width as needed
      worksheet.columns.forEach(column => {
        column.width = columnWidth;
      });

    };


    const headers1 = ["Factory Name", "Country", "Month Of Inventory", "Total Formula Count", "Compliant Formula Count", "Count Compliance Percentage", "Brand Formula Count (used for C&A production)", "Compliant Brand Formula Count  (used for C&A production)", "Brand Count Compliance Percentage  (used for C&A production)", "Total Consumption", "Compliant Consumption", "Consumption Compliance Percentage", "Brand Consumption  (used for C&A production)", "Compliant Brand Consumption  (used for C&A production)", "Brand Consumption Compliance Percentage  (used for C&A production)"];
    const headers2 = ["Factory Name", "Country", "Month Of Inventory", "Total Formula Count", "Not Compliant Formula Count", "Count Not Compliance Percentage", "Brand Formula Count (used for C&A production)", "Not Compliant Brand Formula Count  (used for C&A production)", "Brand Count Not Compliance Percentage  (used for C&A production)", "Total Consumption", "Not Compliant Consumption", "Consumption Not Compliance Percentage", "Brand Consumption  (used for C&A production)", "Not Compliant Brand Consumption  (used for C&A production)", "Brand Consumption Not Compliance Percentage  (used for C&A production)"];
    const headers3 = ["Country", "Month Of Inventory", "Total Formula Count", "Not Compliant Formula Count", "Count Not Compliance Percentage", "Brand Formula Count (used for C&A production)", "Not Compliant Brand Formula Count  (used for C&A production)", "Brand Count Not Compliance Percentage  (used for C&A production)", "Total Consumption", "Not Compliant Consumption", "Consumption Not Compliance Percentage", "Brand Consumption  (used for C&A production)", "Not Compliant Brand Consumption  (used for C&A production)", "Brand Consumption Not Compliance Percentage  (used for C&A production)"];
    const headers4 = ["Month Of Inventory", "Total Formula Count", "Not Compliant Formula Count", "Count Not Compliance Percentage", "Brand Formula Count (used for C&A production)", "Not Compliant Brand Formula Count  (used for C&A production)", "Brand Count Not Compliance Percentage  (used for C&A production)", "Total Consumption", "Not Compliant Consumption", "Consumption Not Compliance Percentage", "Brand Consumption  (used for C&A production)", "Not Compliant Brand Consumption  (used for C&A production)", "Brand Consumption Not Compliance Percentage  (used for C&A production)"];
    const headers5 = ["Factory Name", "Chemical Name", "Chemicals Manufacturer"];
    const headers6 = ["Factory Name", "Chemical Product", "ZDHC Level", "Version", "Compliance Status", "Gots", "Eco Passport", "Bluesign", "C2C", "GreenScreen", "Toxfmd Scivera", "Test Report"];

    addWorksheet([], [json1], 'Compliance Overview', [headers1]);
    const titles = ["Unverified Chemicals by Factory", "Unverified Chemicals by Country", "Unverified Chemicals Globally", "Unverified Chemical List"]
    const customHeadersList = [headers2, headers3, headers4, headers5];
    const tables = [json2, json3, json4, json5];

    addWorksheet(titles, tables, 'Unverified Chemicals', customHeadersList);

    addWorksheet([], [json6], 'Complete List of Chemicals', [headers6]);

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = excelFileName + '.xlsx';
      link.click();
    });
  }

  public exportRawDataAsExcelFile(json: any[], excelFileName: string, isBrandZdhc: boolean): void {
    const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const myworkbook: XLSX.WorkBook = XLSX.utils.book_new();
    if (isBrandZdhc) {
      XLSX.utils.sheet_add_aoa(myworksheet, [["ZDHC AID", "Higg ID", "Supplier ID", "Factory Name", "Date of Scan", "Formula Type", "Compliance Status", "Chemical Product Name", "Chemical Manufacturer", "Gateway Product Name", "Gateway manufacturer Name", "Gateway Level", "ZDHC Use Category (The BHive database)", "Applications / Use Areas (ZDHC use category)", "Category", "Consumption", "Stock Volume", "Delivered Quantity", "Commodity", "Product Type", "Batch / Lot Number", "CAS No.",
        "Notes", "Use of PPE", "GHS MSDS available", "Precautionairy Statement", "GHS Hazard Statement", "GHS Classification", "Stock location", "Storage condition", "Minimum stock", "Maximum stock", "Manufacturing date", "Purchase Date", "Expiry date", "Used for " + excelFileName, "ZDHC MRSL", "ZDHC Level", "ZDHC MRSL Version", "ZDHC MRSL Self Declaration", "GOTS", "GOTS version", "ECO PASSPORT", "Bluesign", "Bluesign System Partner", "Green Screen", "Green Screen level", "C2C", "The List > 3 Years", "The List < 3 Years", "ToxFMD", "Scivera", "Test Report", "Testing Facility", "SVHC", "Usage / Delivery"]], { origin: "A1" });

    }
    else {
      XLSX.utils.sheet_add_aoa(myworksheet, [["ZDHC AID", "Higg ID", "Supplier ID", "Factory Name", "Date of Scan", "Formula Type", "Compliance Status", "Chemical Product Name",
        "Chemical Manufacturer", "ZDHC Use Category (The BHive database)", "Applications / Use Areas (ZDHC use category)", "Category", "Consumption", "Stock Volume", "Delivered Quantity", "Commodity", "Product Type", "Batch / Lot Number", "CAS No.",
        "Notes", "Use of PPE", "GHS MSDS available", "Precautionairy Statement", "GHS Hazard Statement", "GHS Classification", "Stock location", "Storage condition", "Minimum stock", "Maximum stock", "Manufacturing date", "Purchase Date", "Expiry date", "Used for " + excelFileName, "ZDHC MRSL", "ZDHC Level", "ZDHC MRSL Version", "ZDHC MRSL Self Declaration", "GOTS", "GOTS version", "ECO PASSPORT", "Bluesign", "Bluesign System Partner", "Green Screen", "Green Screen level", "C2C", "The List > 3 Years", "The List < 3 Years", "ToxFMD", "Scivera", "Test Report", "Testing Facility", "SVHC"]], { origin: "A1" });
    }

    XLSX.utils.book_append_sheet(myworkbook, myworksheet, 'Sheet1');
    XLSX.writeFile(myworkbook, excelFileName + ' Raw Data' + EXCEL_EXTENSION, { bookType: 'xlsx', type: 'file' });
  }

  public exportAsExcelFileFactoryAnalytics(json: any[], excelFileName: string): void {
    var ws = XLSX.utils.aoa_to_sheet([]);
    XLSX.utils.sheet_add_json(ws, json);
    ws['!cols'] = [
      {
        "hidden": false
      },
      {
        "hidden": true
      }
    ]
    var myworkbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(myworkbook, ws, "Sheet1");
    XLSX.writeFile(myworkbook, excelFileName + EXCEL_EXTENSION, { bookType: 'xlsx', type: 'file' });
  }

  public exportInventoryAsExcelFile(json: any[], excelFileName: string, inventoryData: string, zdhcGatewaySubscriptionStatus: boolean, isBrandZdhcMember: Boolean, isBrand: Boolean, loggedInBrandName: string, isRedNice: Boolean): void {

    // if (this.isSupplier) {
    let modifiedJson = json.map((item: any) => {
      const usedForCustomerArray = Array.isArray(item["usedForCustomer"]) ? item["usedForCustomer"].join(", ") : "";
      return {
        ...item,
        "usedForCustomer": usedForCustomerArray,
      };
    });
    modifiedJson = modifiedJson.map((item: any) => {
      const formulaNewUsedForArray = Array.isArray(item["formulaNewUsedFor"]) ? item["formulaNewUsedFor"].join(", ") : "";
      return {
        ...item,
        "formulaNewUsedFor": formulaNewUsedForArray,
      };
    });
    const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(modifiedJson);
    const myworkbook: XLSX.WorkBook = XLSX.utils.book_new();
    if ((zdhcGatewaySubscriptionStatus === true && this.isSupplier) || (isBrandZdhcMember && isBrand && zdhcGatewaySubscriptionStatus)) {
      if (isBrand) {
        if (!isRedNice) {
          if (GlobalsService.isAldi || GlobalsService.isHofer || GlobalsService.isSdsScreeningBrand)
            XLSX.utils.sheet_add_aoa(myworksheet, [["Chemical Name", "Manufacturer Name", "Gateway Name", "Gateway Manufacturer Name", "Source", "ZDHC PID", "The list <3", "The list >3", "Bluesign approved",
              "C2C", "Eco passport", "GOTS approved", "GreenScreen",
              "Screened Chemistry", "SVHC", "ZDHC InCheck Level", "ZDHC CrowsSourced", "SDS Screened", "Purchase Date",
              "Manufacture Date", "Expiry Date", "Used for Customer", "Consumption", "Delivered Quantity", "Stock volume", "Minimum stock",
              "Maximum stock", "Stock Location", "Storage Condition", "Fabric Roll Id", "Batch/Lot number", "General usage in factory(Factory specific)",
              "GHS SDS available", "GHS classification", "Notes",
              "Chemical formulations (Name/CAS Nr/Concentration%)", "General Usage in factory(BHive database)",
              "Applications/ use Areas(Zdhc use category)", "GHS hazard statement", "Precautionary Statement (P)", "Hazardous Substances indicated in SDS", "Non-compatible materials",
              "COD", "AOX", "Inherent biodegradability", "Bioeliminability", "Aquatic toxicity", "Oral toxicity", "Skin/Eye damage", "CMR data", "STOT data", "Reduction in water usage", "Reduction in energy usage", "In Cap"]], { origin: "A1" });

          else 
            XLSX.utils.sheet_add_aoa(myworksheet, [["Chemical Name", "Manufacturer Name", "Gateway Name", "Gateway Manufacturer Name", "Source", "ZDHC PID", "The list <3", "The list >3", "Bluesign approved",
            "C2C", "Eco passport", "GOTS approved", "GreenScreen",
            "Screened Chemistry", "SVHC", "ZDHC InCheck Level", "ZDHC CrowsSourced", "Purchase Date",
            "Manufacture Date", "Expiry Date", "Used for Customer", "Consumption", "Delivered Quantity", "Stock volume", "Minimum stock",
            "Maximum stock", "Stock Location", "Storage Condition", "Fabric Roll Id", "Batch/Lot number", "General usage in factory(Factory specific)",
            "GHS SDS available", "GHS classification", "Notes",
            "Chemical formulations (Name/CAS Nr/Concentration%)", "General Usage in factory(BHive database)",
            "Applications/ use Areas(Zdhc use category)", "GHS hazard statement", "Precautionary Statement (P)", "Hazardous Substances indicated in SDS", "Non-compatible materials",
            "COD", "AOX", "Inherent biodegradability", "Bioeliminability", "Aquatic toxicity", "Oral toxicity", "Skin/Eye damage", "CMR data", "STOT data", "Reduction in water usage", "Reduction in energy usage", "In Cap"]], { origin: "A1" });
        }
        else
          XLSX.utils.sheet_add_aoa(myworksheet, [["Chemical Name", "Manufacturer Name", "Gateway Name", "Gateway Manufacturer Name", "Source", "ZDHC PID", "The list <3", "The list >3", "Bluesign approved",
            "C2C", "Eco passport", "ChemIQ", "ChemIQ Level", "GOTS approved", "GreenScreen",
            "Screened Chemistry", "SVHC", "ZDHC InCheck Level", "ZDHC CrowsSourced", "Purchase Date",
            "Manufacture Date", "Expiry Date", "Used for Customer", "Consumption", "Delivered Quantity", "Stock volume", "Minimum stock",
            "Maximum stock", "Stock Location", "Storage Condition", "Fabric Roll Id", "Batch/Lot number", "General usage in factory(Factory specific)",
            "GHS SDS available", "GHS classification", "Notes",
            "Chemical formulations (Name/CAS Nr/Concentration%)", "General Usage in factory(BHive database)",
            "Applications/ use Areas(Zdhc use category)", "GHS hazard statement", "Precautionary Statement (P)", "Hazardous Substances indicated in SDS", "Non-compatible materials",
            "COD", "AOX", "Inherent biodegradability", "Bioeliminability", "Aquatic toxicity", "Oral toxicity", "Skin/Eye damage", "CMR data", "STOT data", "Reduction in water usage", "Reduction in energy usage", "In Cap"]], { origin: "A1" });
      }
      else {
        if (!isRedNice) {
          if (GlobalsService.isSupplierOfSdsScreeningBhive || GlobalsService.isSupplierOfAldiEinkauf || GlobalsService.isSupplierOfAldiHofer)
            XLSX.utils.sheet_add_aoa(myworksheet, [["Chemical Name", "Manufacturer Name", "Gateway Name", "Gateway Manufacturer Name", "Source", "ZDHC PID", "In Cap", "The list <3", "The list >3", "Bluesign approved",
              "C2C", "Eco passport", "GOTS approved", "GreenScreen",
              "Screened Chemistry", "SVHC", "ZDHC InCheck Level", "ZDHC CrowsSourced", "SDS Screened", "Purchase Date",
              "Manufacture Date", "Expiry Date", "Used for Customer", "Consumption", "Delivered Quantity", "Stock volume", "Minimum stock",
              "Maximum stock", "Stock Location", "Storage Condition", "Fabric Roll Id", "Batch/Lot number", "General usage in factory(Factory specific)",
              "GHS SDS available", "GHS classification", "Notes",
              "Chemical formulations (Name/CAS Nr/Concentration%)", "General Usage in factory(BHive database)",
              "Applications/ use Areas(Zdhc use category)", "GHS hazard statement", "Precautionary Statement (P)", "Hazardous Substances indicated in SDS", "Non-compatible materials",
              "COD", "AOX", "Inherent biodegradability", "Bioeliminability", "Aquatic toxicity", "Oral toxicity", "Skin/Eye damage", "CMR data", "STOT data", "Reduction in water usage", "Reduction in energy usage"]], { origin: "A1" });

          else 
            XLSX.utils.sheet_add_aoa(myworksheet, [["Chemical Name", "Manufacturer Name", "Gateway Name", "Gateway Manufacturer Name", "Source", "ZDHC PID", "In Cap", "The list <3", "The list >3", "Bluesign approved",
            "C2C", "Eco passport", "GOTS approved", "GreenScreen",
            "Screened Chemistry", "SVHC", "ZDHC InCheck Level", "ZDHC CrowsSourced", "Purchase Date",
            "Manufacture Date", "Expiry Date", "Used for Customer", "Consumption", "Delivered Quantity", "Stock volume", "Minimum stock",
            "Maximum stock", "Stock Location", "Storage Condition", "Fabric Roll Id", "Batch/Lot number", "General usage in factory(Factory specific)",
            "GHS SDS available", "GHS classification", "Notes",
            "Chemical formulations (Name/CAS Nr/Concentration%)", "General Usage in factory(BHive database)",
            "Applications/ use Areas(Zdhc use category)", "GHS hazard statement", "Precautionary Statement (P)", "Hazardous Substances indicated in SDS", "Non-compatible materials",
            "COD", "AOX", "Inherent biodegradability", "Bioeliminability", "Aquatic toxicity", "Oral toxicity", "Skin/Eye damage", "CMR data", "STOT data", "Reduction in water usage", "Reduction in energy usage"]], { origin: "A1" });
        }
        else
          XLSX.utils.sheet_add_aoa(myworksheet, [["Chemical Name", "Manufacturer Name", "Gateway Name", "Gateway Manufacturer Name", "Source", "ZDHC PID", "In Cap", "The list <3", "The list >3", "Bluesign approved",
            "C2C", "Eco passport", "ChemIQ", "ChemIQ Level", "GOTS approved", "GreenScreen",
            "Screened Chemistry", "SVHC", "ZDHC InCheck Level", "ZDHC CrowsSourced", "Purchase Date",
            "Manufacture Date", "Expiry Date", "Used for Customer", "Consumption", "Delivered Quantity", "Stock volume", "Minimum stock",
            "Maximum stock", "Stock Location", "Storage Condition", "Fabric Roll Id", "Batch/Lot number", "General usage in factory(Factory specific)",
            "GHS SDS available", "GHS classification", "Notes",
            "Chemical formulations (Name/CAS Nr/Concentration%)", "General Usage in factory(BHive database)",
            "Applications/ use Areas(Zdhc use category)", "GHS hazard statement", "Precautionary Statement (P)", "Hazardous Substances indicated in SDS", "Non-compatible materials",
            "COD", "AOX", "Inherent biodegradability", "Bioeliminability", "Aquatic toxicity", "Oral toxicity", "Skin/Eye damage", "CMR data", "STOT data", "Reduction in water usage", "Reduction in energy usage"]], { origin: "A1" });
      }
    }
    else {
      if (isBrand) {
        if (!isRedNice) {
          if (GlobalsService.isAldi || GlobalsService.isHofer || GlobalsService.isSdsScreeningBrand)
            XLSX.utils.sheet_add_aoa(myworksheet, [["Chemical Name", "Manufacturer Name", "Source", "ZDHC PID", "The list <3", "The list >3", "Bluesign approved",
              "C2C", "Eco passport", "GOTS approved", "GreenScreen",
              "Screened Chemistry", "SVHC", "ZDHC CrowsSourced", "SDS Screened", "Purchase Date",
              "Manufacture Date", "Expiry Date", "Used for Customer", "Consumption", "Delivered Quantity", "Stock volume", "Minimum stock",
              "Maximum stock", "Stock Location", "Storage Condition", "Fabric Roll Id", "Batch/Lot number", "General usage in factory(Factory specific)",
              "GHS SDS available", "GHS classification", "Notes",
              "Chemical formulations (Name/CAS Nr/Concentration%)", "General Usage in factory(BHive database)",
              "Applications/ use Areas(Zdhc use category)", "GHS hazard statement", "Precautionary Statement (P)", "Hazardous Substances indicated in SDS", "Non-compatible materials",
              "COD", "AOX", "Inherent biodegradability", "Bioeliminability", "Aquatic toxicity", "Oral toxicity", "Skin/Eye damage", "CMR data", "STOT data", "Reduction in water usage", "Reduction in energy usage", "In Cap"]], { origin: "A1" });

          else
            XLSX.utils.sheet_add_aoa(myworksheet, [["Chemical Name", "Manufacturer Name", "Source", "ZDHC PID", "The list <3", "The list >3", "Bluesign approved",
            "C2C", "Eco passport", "GOTS approved", "GreenScreen",
            "Screened Chemistry", "SVHC", "ZDHC CrowsSourced", "Purchase Date",
            "Manufacture Date", "Expiry Date", "Used for Customer", "Consumption", "Delivered Quantity", "Stock volume", "Minimum stock",
            "Maximum stock", "Stock Location", "Storage Condition", "Fabric Roll Id", "Batch/Lot number", "General usage in factory(Factory specific)",
            "GHS SDS available", "GHS classification", "Notes",
            "Chemical formulations (Name/CAS Nr/Concentration%)", "General Usage in factory(BHive database)",
            "Applications/ use Areas(Zdhc use category)", "GHS hazard statement", "Precautionary Statement (P)", "Hazardous Substances indicated in SDS", "Non-compatible materials",
            "COD", "AOX", "Inherent biodegradability", "Bioeliminability", "Aquatic toxicity", "Oral toxicity", "Skin/Eye damage", "CMR data", "STOT data", "Reduction in water usage", "Reduction in energy usage", "In Cap"]], { origin: "A1" });
        }
        else
          XLSX.utils.sheet_add_aoa(myworksheet, [["Chemical Name", "Manufacturer Name", "Source", "ZDHC PID", "The list <3", "The list >3", "Bluesign approved",
            "C2C", "Eco passport", "ChemIQ", "ChemIQ Level", "GOTS approved", "GreenScreen",
            "Screened Chemistry", "SVHC", "ZDHC CrowsSourced", "Purchase Date",
            "Manufacture Date", "Expiry Date", "Used for Customer", "Consumption", "Delivered Quantity", "Stock volume", "Minimum stock",
            "Maximum stock", "Stock Location", "Storage Condition", "Fabric Roll Id", "Batch/Lot number", "General usage in factory(Factory specific)",
            "GHS SDS available", "GHS classification", "Notes",
            "Chemical formulations (Name/CAS Nr/Concentration%)", "General Usage in factory(BHive database)",
            "Applications/ use Areas(Zdhc use category)", "GHS hazard statement", "Precautionary Statement (P)", "Hazardous Substances indicated in SDS", "Non-compatible materials",
            "COD", "AOX", "Inherent biodegradability", "Bioeliminability", "Aquatic toxicity", "Oral toxicity", "Skin/Eye damage", "CMR data", "STOT data", "Reduction in water usage", "Reduction in energy usage", "In Cap"]], { origin: "A1" });

      }
      else {
        if (!isRedNice) {
          if (GlobalsService.isSupplierOfSdsScreeningBhive || GlobalsService.isSupplierOfAldiEinkauf || GlobalsService.isSupplierOfAldiHofer)
            XLSX.utils.sheet_add_aoa(myworksheet, [["Chemical Name", "Manufacturer Name", "Source", "ZDHC PID", "In Cap", "The list <3", "The list >3", "Bluesign approved",
              "C2C", "Eco passport", "GOTS approved", "GreenScreen",
              "Screened Chemistry", "SVHC", "ZDHC CrowsSourced", "SDS Screened", "Purchase Date",
              "Manufacture Date", "Expiry Date", "Used for Customer", "Consumption", "Delivered Quantity", "Stock volume", "Minimum stock",
              "Maximum stock", "Stock Location", "Storage Condition", "Fabric Roll Id", "Batch/Lot number", "General usage in factory(Factory specific)",
              "GHS SDS available", "GHS classification", "Notes",
              "Chemical formulations (Name/CAS Nr/Concentration%)", "General Usage in factory(BHive database)",
              "Applications/ use Areas(Zdhc use category)", "GHS hazard statement", "Precautionary Statement (P)", "Hazardous Substances indicated in SDS", "Non-compatible materials",
              "COD", "AOX", "Inherent biodegradability", "Bioeliminability", "Aquatic toxicity", "Oral toxicity", "Skin/Eye damage", "CMR data", "STOT data", "Reduction in water usage", "Reduction in energy usage"]], { origin: "A1" });

          else
            XLSX.utils.sheet_add_aoa(myworksheet, [["Chemical Name", "Manufacturer Name", "Source", "ZDHC PID", "In Cap", "The list <3", "The list >3", "Bluesign approved",
            "C2C", "Eco passport", "GOTS approved", "GreenScreen",
            "Screened Chemistry", "SVHC", "ZDHC CrowsSourced", "Purchase Date",
            "Manufacture Date", "Expiry Date", "Used for Customer", "Consumption", "Delivered Quantity", "Stock volume", "Minimum stock",
            "Maximum stock", "Stock Location", "Storage Condition", "Fabric Roll Id", "Batch/Lot number", "General usage in factory(Factory specific)",
            "GHS SDS available", "GHS classification", "Notes",
            "Chemical formulations (Name/CAS Nr/Concentration%)", "General Usage in factory(BHive database)",
            "Applications/ use Areas(Zdhc use category)", "GHS hazard statement", "Precautionary Statement (P)", "Hazardous Substances indicated in SDS", "Non-compatible materials",
            "COD", "AOX", "Inherent biodegradability", "Bioeliminability", "Aquatic toxicity", "Oral toxicity", "Skin/Eye damage", "CMR data", "STOT data", "Reduction in water usage", "Reduction in energy usage"]], { origin: "A1" });
        }
        else
          XLSX.utils.sheet_add_aoa(myworksheet, [["Chemical Name", "Manufacturer Name", "Source", "ZDHC PID", "In Cap", "The list <3", "The list >3", "Bluesign approved",
            "C2C", "Eco passport", "ChemIQ", "ChemIQ Level", "GOTS approved", "GreenScreen",
            "Screened Chemistry", "SVHC", "ZDHC CrowsSourced", "Purchase Date",
            "Manufacture Date", "Expiry Date", "Used for Customer", "Consumption", "Delivered Quantity", "Stock volume", "Minimum stock",
            "Maximum stock", "Stock Location", "Storage Condition", "Fabric Roll Id", "Batch/Lot number", "General usage in factory(Factory specific)",
            "GHS SDS available", "GHS classification", "Notes",
            "Chemical formulations (Name/CAS Nr/Concentration%)", "General Usage in factory(BHive database)",
            "Applications/ use Areas(Zdhc use category)", "GHS hazard statement", "Precautionary Statement (P)", "Hazardous Substances indicated in SDS", "Non-compatible materials",
            "COD", "AOX", "Inherent biodegradability", "Bioeliminability", "Aquatic toxicity", "Oral toxicity", "Skin/Eye damage", "CMR data", "STOT data", "Reduction in water usage", "Reduction in energy usage"]], { origin: "A1" });

      }
    }

    XLSX.utils.book_append_sheet(myworkbook, myworksheet, 'Inventory' + ' ' + inventoryData);
    XLSX.writeFile(myworkbook, excelFileName + EXCEL_EXTENSION, { bookType: 'xlsx', type: 'file' });

  }

  public exportStepDashboardAsExcelFile(dashboardData: any[], chemicalData: any[], substanceData: any[], month: string, year: string, factoryName: string): void {
    let workbook = new Workbook();
    // fill first worksheet
    let worksheet1 = workbook.addWorksheet('Dashboard Data');
    if (dashboardData.length > 0) {
      worksheet1.mergeCells('A1', 'K1');
      let factory_name = worksheet1.getCell('A1');
      factory_name.value = 'Performance: ' + factoryName;
      factory_name.font = {
        name: 'Calibri',
        size: 20,
        bold: true,
      };
      factory_name.alignment = { vertical: 'middle', horizontal: 'center' };
      factory_name.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };
      factory_name.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'F2F2F2' },
        bgColor: { argb: '' }
      };
      worksheet1.mergeCells('A2', 'K2');
      let date = worksheet1.getCell('C2');
      if (month.includes('Summary'))
        date.value = month
      else date.value = month + ' ' + year;
      date.font = {
        name: 'Calibri',
        italic: true,
        size: 12,
        bold: true,
      };
      date.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };
      date.alignment = { vertical: 'middle', horizontal: 'center' };
      worksheet1.addRow([]);
      let headerRow = worksheet1.addRow(Object.keys(dashboardData[0]));
      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'B7DEE8' },
          bgColor: { argb: '' }
        };
        cell.font = {
          size: 12,
          bold: true,
        };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
      });

      let descriptions = ['', 'How many chemical products have been uploaded in the inventory summary of the selected time span.', 'DETOX TO Zero compliance (compliant with ZDHC MSRL and/ or ECO PASSPORT certified and/or STeP MRSL Self-Declaration)', '', 'Minimum and maximum stock', 'Application/Used Areas (ZDHC use category)', 'Storage Location', 'Composition of the substance', 'GHS Classifications', 'H-Phrases', 'P-Phrases']
      let descriptionRow = worksheet1.addRow(descriptions);
      descriptionRow.height = 80;
      descriptionRow.alignment = { vertical: 'middle', horizontal: 'center' };
      descriptionRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'DBEEF4' },
          bgColor: { argb: '' }
        };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
        cell.font = {
          size: 12,
        };

        cell.alignment = {
          wrapText: true,
          horizontal: 'left',
          vertical: 'middle',
        };
      });

      worksheet1.mergeCells('A4:A5');
      worksheet1.getCell('A4').alignment = { vertical: 'middle', horizontal: 'center' };
      worksheet1.columns = [
        { width: 25 },
        { width: 25 },
        { width: 25 },
        { width: 25 },
        { width: 25 },
        { width: 25 },
        { width: 25 },
        { width: 25 },
        { width: 25 },
        { width: 25 }
      ];
      dashboardData.forEach((d: any) => {
        let row = worksheet1.addRow(Object.values(d));
        row.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
      });

      worksheet1.addRow([]);
    }

    //fill second worksheet
    let worksheet2 = workbook.addWorksheet('Chemical Details');
    if (chemicalData.length > 0) {
      let headerRow2 = worksheet2.addRow(Object.keys(chemicalData[0]));
      headerRow2.eachCell(cell => {
        cell.font = { bold: true }
      })
      chemicalData.forEach((d: any) => {
        let row = worksheet2.addRow(Object.values(d));
      });
      worksheet2.addRow([]);
      worksheet2.columns = [
        { header: 'Factory Name', width: 32 },
        { header: 'Chemical Name', width: 32 },
        { header: 'Manufacturer', width: 32 },
        { header: 'Commodities', width: 32 },
        { header: 'Country', width: 32 },
        { header: 'Scan_Date', width: 32 },
        { header: 'Consumption', width: 32 },
        { header: 'Minimum Stock', width: 32 },
        { header: 'Maximum Stock', width: 32 },
        { header: 'Stock Location', width: 32 },
        { header: 'Factory Usage', width: 32 },
        { header: 'Step_Self_Declaration', width: 32 },
        { header: 'ZDHC_MRSL', width: 32 },
        { header: 'Eco_Passport', width: 32 },
        { header: 'Gateway Level', width: 32 },
        { header: 'GHS Classifications', width: 32 },
        { header: 'Precautionary Statement', width: 32 },
        { header: 'Hazard Statement', width: 32 }
      ];
    }

    // fill third worksheet
    let worksheet3 = workbook.addWorksheet('Substances');
    if (chemicalData.length > 0) {
      let headerRow3 = worksheet3.addRow(Object.keys(substanceData[0]));
      headerRow3.eachCell(cell => {
        cell.font = { bold: true }
      })
      substanceData.forEach((d: any) => {
        let row = worksheet3.addRow(Object.values(d));
      });
      worksheet3.addRow([]);
      worksheet3.columns = [
        { header: 'Chemical_Name', width: 32 },
        { header: 'Factory_Name', width: 32 },
        { header: 'Manufacturer', width: 32 },
        { header: 'CAS_Number', width: 32 },
        { header: 'Substance_Name', width: 32 },
        { header: 'Percentage', width: 32 },
      ];
    }

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, factoryName + '_' + month + '_' + 'Oekotex_Data_Dash_Details' + '.xlsx');
    })
  }

  public exportKontoorReportAsExcelFile(data: any[], fileName: string) {
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Kontoor Monthly Report');
    if (data.length > 0) {
      let headerRow = worksheet.addRow(Object.keys(data[0]));
      headerRow.eachCell(cell => {
        cell.font = { bold: true }
      })
      headerRow.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'F2F2F2' },
        bgColor: { argb: '' }
      };
      data.forEach((d: any) => {
        let row = worksheet.addRow(Object.values(d));
      });
      worksheet.properties.defaultColWidth = 32;
      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, fileName + '.xlsx');
      })

    }

  }

  public export_csvNikeFile(data: any[]) {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const csvData = XLSX.utils.sheet_to_csv(worksheet, { FS: ',' }); // Set the field separator as a comma
    const dataBlob: Blob = new Blob([csvData], { type: 'text/csv' });
    const url: string = window.URL.createObjectURL(dataBlob);
    const anchor: HTMLAnchorElement = document.createElement('a');
    anchor.href = url;
    anchor.download = 'Chemistry Upload.csv';
    anchor.click();
    window.URL.revokeObjectURL(url);
  }

  public exportJsonInditex(json: any[], excelFileName: string) {
    try {
      const jsonData = JSON.stringify(json);
      const blob = new Blob([jsonData], { type: 'application/json' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = excelFileName + '.json';
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error exporting JSON:', error);
    }
  }

  public exportMilesReportAsExcelFile(data: any[], totals: any[], excelFileName: string): void {
    let workbook = new Workbook();
    let worksheet1 = workbook.addWorksheet('Miles Report');
    worksheet1.mergeCells('A1', 'C1');
    let factory_name = worksheet1.getCell('A1')
    factory_name.value = 'Factories Info'
    factory_name.font = {
      name: 'Calibri',
      size: 12,
      bold: true,
    };
    factory_name.alignment = { vertical: 'middle', horizontal: 'center' };
    factory_name.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFE699' },
      bgColor: { argb: '' }
    };
    worksheet1.mergeCells('D1', 'H1');
    let compliance = worksheet1.getCell('D1')
    compliance.value = 'General Compliance'
    compliance.font = {
      name: 'Calibri',
      size: 12,
      bold: true,
    };
    compliance.alignment = { vertical: 'middle', horizontal: 'center' };
    compliance.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'C6E0B4' },
      bgColor: { argb: '' }
    };
    worksheet1.mergeCells('I1', 'M1');
    let aldi_compliance = worksheet1.getCell('I1')
    aldi_compliance.value = 'ALDI Production (compliance)'
    aldi_compliance.font = {
      name: 'Calibri',
      size: 12,
      bold: true,
    };
    aldi_compliance.alignment = { vertical: 'middle', horizontal: 'center' };
    aldi_compliance.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'BDD7EE' },
      bgColor: { argb: '' }
    };
    worksheet1.mergeCells('N1', 'R1');
    let status = worksheet1.getCell('N1')
    status.value = 'Connection Status'
    status.font = {
      name: 'Calibri',
      size: 12,
      bold: true,
    };
    status.alignment = { vertical: 'middle', horizontal: 'center' };
    status.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'F8CBAD' },
      bgColor: { argb: '' }
    };
    let titles = ['Factory Name', 'Country', 'Inventory Date ', 'Compliance Level (%)', 'Number of Chemicals (tot)', 'Verified Count', 'Unverified Count', 'Commodities Count', 'Nr. Chemicals for ALDI (tot)', 'Verified Count', 'Unverified Count', 'Commodities Count', 'Compliance level (%)', 'Connected to MILES', 'Connection request sent on (date)', 'Status of the connection request', 'Connected to ALDI Einkauf', 'Connected to ALDI SÜD KG']
    let titlesRow = worksheet1.addRow(titles);
    titlesRow.eachCell((cell, index) => {
      if (index <= 3) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFF2CC' },
          bgColor: { argb: '' }
        };
      }
      else if (index > 3 && index <= 8) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'E2EFDA' },
          bgColor: { argb: '' }
        };
      }
      if (index > 8 && index <= 13) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'DDEBF7' },
          bgColor: { argb: '' }
        };
      }
      if (index > 13 && index <= 18) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FCE4D6' },
          bgColor: { argb: '' }
        };
      }
    });
    worksheet1.columns = [
      { width: 25 },
      { width: 25 },
      { width: 25 },
      { width: 25 },
      { width: 25 },
      { width: 25 },
      { width: 25 },
      { width: 25 },
      { width: 25 },
      { width: 25 },
      { width: 25 },
      { width: 25 },
      { width: 25 },
      { width: 25 },
      { width: 25 },
      { width: 25 },
      { width: 25 }
    ];
    data.forEach((d: any) => {
      let row = worksheet1.addRow(Object.values(d));
    });
    let worksheet2 = workbook.addWorksheet('Total Compliance');
    worksheet2.columns = [
      {
        header: 'tot % compliance for all factories', width: 32
      },

      {
        header: 'Aldi Production Compliance', width: 32
      }
    ]
    worksheet2.getCell('A1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFF00' },
      bgColor: { argb: '' }
    };
    worksheet2.getCell('B1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFF00' },
      bgColor: { argb: '' }
    };;

    totals.forEach((d: any) => {
      let row = worksheet2.addRow(Object.values(d));
    });

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, excelFileName);
    })





  }




  downloadAldiCapTemplateWithData(unVerifiedFormulas, fileName: string, suuplierName, supplierAddress, invDate) {
    const url = 'assets/dataTemplate/CAP_Template_EC.xlsx';

    const workbook = new ExcelJS.Workbook();

    fetch(url)
      .then(response => response.arrayBuffer())
      .then(data => workbook.xlsx.load(data))
      .then(() => {
        const worksheet = workbook.getWorksheet(1);

        worksheet.getCell(`D2`).value = suuplierName
        worksheet.getCell(`D3`).value = supplierAddress

        worksheet.getCell(`D6`).value = invDate


        let id = 9
        unVerifiedFormulas.
          forEach(fm => {
            worksheet.getCell(`A${id}`).value = fm.name
            worksheet.getCell(`B${id}`).value = fm.manufacturer
            ++id
          });


        // Write the modified workbook to a buffer
        return workbook.xlsx.writeBuffer();
      })
      .then(buffer => {
        const blob = new Blob([buffer], { type: 'application/octet-stream' });
        this.saveExcelFile(blob, fileName);
      })
      .catch(error => {
        console.error('Error loading or modifying the Excel file:', error);
      });
  }

  saveExcelFile(blob: Blob, fileName: string) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  // Helper function to convert the binary string to ArrayBuffer
  s2ab(s: string): ArrayBuffer {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i) & 0xFF;
    }
    return buf;
  }


}
