<div class="flex flex-col flex-auto min-w-0">
    <div class="flex-auto p-2 sm:p-10">
        <div class="content header">  
            <div class="flex items-center justify-between w-full ">
                <h2  class="headers text-3xl font-semibold tracking-tight leading-8 flex-auto"
                    i18n="Inventories Overview Component header">
                    {{ this.organization_name + ' '+ ('DEFAULT_BRAND_MONTHLY_REPORT.PAGE_HEADER' | translate) }}
                </h2>
            </div>          

            <div>
                <h2 class="headers page-sub-header" i18n="Inventories Overview Component sub header">
                    {{('DEFAULT_BRAND_MONTHLY_REPORT.PAGE_SUB_HEADER') | translate }}
                </h2>
            </div>
        </div> 
        <div class="content mt-4">  
            <div class="exportButtonContainer">
                <button class="btn btn-primary exportButton" style="color:white" (click)="exportData()">                       
                {{'BRAND_MONTHLY_REPORT.PAGE_HEADER' | translate }} 
                <mat-icon style="color:white; vertical-align: middle" [svgIcon]="'mat_outline:file_download'"></mat-icon>
                </button>
            </div>

            <div>
                <div class="h-full kontoorDataTable">
                    <table mat-table [dataSource]="monthlyDataList" class="monthlyDataTable" #monthlyDataMatSort="matSort" matSort>

                        <ng-container matColumnDef="organization_id">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="input-header1">ORG_ID</th>
                            <td mat-cell *matCellDef="let formula" >
                                {{formula.organization_id}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="factory_name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="input-header1">Factory Name</th>
                            <td mat-cell *matCellDef="let formula">
                                {{formula.factory_name}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="country_name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="input-header1">Country</th>
                            <td mat-cell *matCellDef="let formula">
                                {{formula.country_name}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="email">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="input-header1">Email</th>
                            <td mat-cell *matCellDef="let formula">
                                {{formula.email}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="mill_ids">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="input-header1">Supplier Ids</th>
                            <td mat-cell *matCellDef="let formula">
                                {{formula.mill_ids}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="in_scope">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="input-header1">In Scope</th>
                            <td mat-cell *matCellDef="let formula">
                                {{formula.in_scope}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="is_connected">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="input-header1">Connected to {{ organization_name}}</th>
                            <td mat-cell *matCellDef="let formula" [ngClass]="{'yes': formula.is_connected === 'Yes', 'no': formula.is_connected === 'No'}">
                                {{formula.is_connected}}
                            </td>
                        </ng-container>


                        <ng-container matColumnDef="scanned_two_months_ago">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="input-header1">Scanned 2 Months Ago</th>
                            <td mat-cell *matCellDef="let formula" [ngClass]="{'yes': formula.scanned_two_months_ago === 'Yes', 'no': formula.scanned_two_months_ago === 'No'}">
                                {{formula.scanned_two_months_ago}}
                            </td>
                        </ng-container>

                        <ng-container *ngIf="isBrandZdhc" matColumnDef="generated_incheck_two_months_ago">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="input-header1">Created InCheck Report 2 Months Ago</th>
                            <td mat-cell *matCellDef="let formula" [ngClass]="{'yes': formula.generated_incheck_two_months_ago === 'Yes', 'no': formula.generated_incheck_two_months_ago === 'No'}">
                                {{formula.generated_incheck_two_months_ago}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="two_month_compliance">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="input-header1">Performance % Two Months ago</th>
                            <td mat-cell *matCellDef="let formula" [ngClass]="{'no': formula.two_month_compliance === 'Not Connected'}">
                                {{formula.two_month_compliance}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="scanned_prev_month">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="input-header1">Scanned in Last Month</th>
                            <td mat-cell *matCellDef="let formula" [ngClass]="{'yes': formula.scanned_prev_month === 'Yes', 'no': formula.scanned_prev_month === 'No'}">
                                {{formula.scanned_prev_month}}
                            </td>
                        </ng-container>

                        <ng-container *ngIf="isBrandZdhc" matColumnDef="generated_incheck_prev_month">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="input-header1">Created InCheck Report Last Month</th>
                            <td mat-cell *matCellDef="let formula" [ngClass]="{'yes': formula.generated_incheck_prev_month === 'Yes', 'no': formula.generated_incheck_prev_month === 'No'}">
                                {{formula.generated_incheck_prev_month}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="prev_month_compliance">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="input-header1">Performance % Last Month</th>
                            <td mat-cell *matCellDef="let formula" [ngClass]="{'no': formula.prev_month_compliance === 'Not Connected'}">
                                {{formula.prev_month_compliance}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="scanned_current_month">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="input-header1">Scanned Current Month</th>
                            <td mat-cell *matCellDef="let formula" [ngClass]="{'yes': formula.scanned_current_month === 'Yes', 'no': formula.scanned_current_month === 'No'}">
                                {{formula.scanned_current_month}}
                            </td>
                        </ng-container>

                        <ng-container *ngIf="isBrandZdhc" matColumnDef="generated_incheck_current_month">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="input-header1">Created InCheck Report Current Month</th>
                            <td mat-cell *matCellDef="let formula" [ngClass]="{'yes': formula.generated_incheck_current_month === 'Yes', 'no': formula.generated_incheck_current_month === 'No'}">
                                {{formula.generated_incheck_current_month}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="current_month_compliance">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="input-header1">Performance % Current Month</th>
                            <td mat-cell *matCellDef="let formula" [ngClass]="{'no': formula.current_month_compliance === 'Not Connected'}">
                                {{formula.current_month_compliance}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="scanned_at_least_once_in_quarter">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="input-header1">Scanned At Least Once this Quarter</th>
                            <td mat-cell *matCellDef="let formula" [ngClass]="{'yes': formula.scanned_at_least_once_in_quarter === 'Yes', 'no': formula.scanned_at_least_once_in_quarter === 'No'}">
                                {{formula.scanned_at_least_once_in_quarter}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="prev_quarter_compliance">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="input-header1">Last Quarter Performance</th>
                            <td mat-cell *matCellDef="let formula">
                                {{formula.prev_quarter_compliance}}
                            </td>
                        </ng-container>

                        <!-- <ng-container matColumnDef="uploaded_ww1">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="input-header1">Wastewater Test Report V1.1: Upload in last 12 month</th>
                            <td mat-cell *matCellDef="let formula" [ngClass]="{'yes': formula.uploaded_ww1 === 'Yes', 'no': formula.uploaded_ww1 === 'No'}">
                                {{formula.uploaded_ww1}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="uploaded_ww2">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="input-header1">Wastewater Test Report V2.1: Upload in last 12 month</th>
                            <td mat-cell *matCellDef="let formula" [ngClass]="{'yes': formula.uploaded_ww2 === 'Yes', 'no': formula.uploaded_ww2 === 'No'}">
                                {{formula.uploaded_ww2}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="ww_result">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="input-header1">Latest Wastewater Test Report - Results</th>
                            <td mat-cell *matCellDef="let formula" [ngClass]="{'yes': formula.ww_result !== 'No result' && formula.ww_result !== 'Not Connected' && formula.ww_result !== null, 'no': formula.ww_result === 'No result' || formula.ww_result === 'Not Connected'}">
                                {{formula.ww_result}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="latest_ww_date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="input-header1">Last Upload Date</th>
                            <td mat-cell *matCellDef="let formula" [ngStyle]="getCellStyle(formula.latest_ww_date)">
                                {{formula.latest_ww_date}}
                            </td>
                        </ng-container> -->

                          <!-- Header row first group -->
                        <ng-container matColumnDef="factory-info-header">
                            <th mat-header-cell *matHeaderCellDef 
                                class="factory-info-header first-row"
                                [attr.colspan]="5"> 
                            Factory Information
                            </th>
                        </ng-container>
                        <ng-container matColumnDef="scope-header">
                            <th mat-header-cell *matHeaderCellDef 
                                class="scope-header first-row"
                                [attr.colspan]="1"> 
                           SCOPE
                            </th>
                        </ng-container>
                        <ng-container matColumnDef="connection-status-header">
                            <th mat-header-cell *matHeaderCellDef 
                                class="connection-status-header first-row"
                                [attr.colspan]="1"> 
                           Connection Status
                            </th>
                        </ng-container>
                        <ng-container matColumnDef="input-header">
                            <th mat-header-cell *matHeaderCellDef 
                                class="input-header first-row"
                                [attr.colspan]="this.isBrandZdhc ? 10 : 7 "> 
                           INPUT
                            </th>
                        </ng-container>
                        <ng-container matColumnDef="empty-header">
                            <th mat-header-cell *matHeaderCellDef 
                                class="empty-header first-row"
                                [attr.colspan]="1"> 
                            
                            </th>
                        </ng-container>
                        <!-- <ng-container matColumnDef="output-header">
                            <th mat-header-cell *matHeaderCellDef 
                                class="output-header first-row"
                                [attr.colspan]="4"> 
                           Output
                            </th>
                        </ng-container> -->

                        <!-- add 'output-header' in the row above after adding the output -->
                        
                        <tr mat-header-row *matHeaderRowDef="['factory-info-header', 'scope-header', 'connection-status-header', 'input-header', 'empty-header']"></tr>
                        <tr mat-header-row></tr>
                        <tr mat-header-row *matHeaderRowDef="monthlyData; sticky: true;"></tr>
                        <tr mat-row *matRowDef="let row; columns: monthlyData;"></tr>

                    </table>
                    <div *ngIf="hasNotData" style="text-align:center;">
                    No data is available
                    </div>
                    <loading-spinner *ngIf="isLoadingMonthlyDataInfo"></loading-spinner>                       
                </div>
                <mat-paginator #monthlyDataPaginator="matPaginator" sticky [pageSizeOptions]="pageSizeOptions"
                showFirstLastButtons></mat-paginator>
            </div>

    </div>
    
</div>